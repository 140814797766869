import { fabric } from "fabric";

export const getTemplateId = () => {
  const u = new URL(window.location);
  return u.searchParams.get("templateId");
};

export const getDesignId = () => {
  const u = new URL(window.location);
  // return u.pathname.split("/").pop();
  return u.pathname.split("/v/")[1].split("/")[0];
};

export const FontsList = [
  "Arimo",
  "Tinos",
  "Modak",
  "Dancing Script",
  "Lobster",
  "Press Start 2P",
  "Monoton",
  "Pinyon Script",
  "Mrs Saint Delafield",
  "Changa One",
  "Love Ya Like A Sister",
  "UnifrakturMaguntia",
  "Cutive",
  "Limelight",
  "Spicy Rice",
  "Mouse Memoirs",
  "Bad Script",
  "Homemade Apple",
  "BenchNine",
  "Cinzel",
  "Permanent Marker",
  "Fredoka One",
  "Amatic SC",
  "Comfortaa",
  "Indie Flower",
  "Pacifico",
  "Fjalla One",
  "PT Serif",
  "Roboto",
  "DM Sans",
  "Open Sans",
  "Montserrat",
  "Lora",
  "Alex Brush",
  "Bungee Shade",
  "Covered By Your Grace",
  "Crafty Girls",
  "Graduate",
  "Great Vibes",
  "Julius Sans One",
  "League Script",
  "Miss Fajardose",
  "Rouge Script",
  "Sacramento",
  "Sniglet",
  "Sofia",
  "Alfa Slab One",
  "Biryani",
  "Black Ops One",
  "Courier Prime",
  "Fredericka the Great",
  "Grand Hotel",
  "Josefin Sans",
  "Metal Mania",
  "Parisienne",
  "Raleway",
  "Rampart One",
  "Slackey",
  "Sorts Mill Goudy",
  "Damion",
  "Source Code Pro",
].sort();
export const textList = [
  {
    id: 1,
    title: "Heading Title",
    name: "heading-title",
    fontFamily: "DM Sans",
    fontWeight: "bold",
    type: "normal",
    fill: "rgba(66, 165, 245, 1)",
    underline: true,
    fontSize: 108,
  },
  {
    id: 2,
    title: "Simple",
    name: "simple",
    type: "normal",
    fontFamily: "Montserrat",
    fontWeight: "600",
  },
  {
    id: 3,
    title: "Handwriting",
    name: "handwriting",
    type: "normal",
    fontFamily: "Sacramento",
    fontWeight: "400",
    fill: "#7C4DFF",
  },
  {
    id: 4,
    title: "Neon Light",
    name: "Neon Light",
    type: "normal",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "72",
    stroke: "#E040FB",
    strokeWidth: 4,
    shadow: new fabric.Shadow({
      color: "#f2a7ff",
      blur: 25,
    }),
  },
  {
    id: 5,
    title: "Boxed",
    name: "Boxed",
    type: "normal",
    fontFamily: "Source Code Pro",
    fontSize: "72",
    fontWeight: "700",
    boxed: true,
  },
  {
    id: 6,
    title: "Groovy",
    name: "Groovy",
    type: "normal",
    fontFamily: "Monoton",
    fontWeight: "400",
    fontSize: "72",
    fill: "rgb(255, 111, 0)",
  },
  {
    id: 7,
    title: "3D Effect",
    name: "3D Effect",
    type: "normal",
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: "72",
    fill: "#1DE9B6",
    shadow: new fabric.Shadow({
      color: "#00796B",
      offsetX: 2,
      offsetY: 6,
    }),
  },
];
export const fontFamilyOptions = FontsList.map((font) => ({
  type: "Popular",
  value: font,
  fontFamily: font,
}));

export const fontSizeOptions = [
  {
    label: "8px",
    value: "8",
  },
  {
    label: "12px",
    value: "12",
  },
  {
    label: "16px",
    value: "16",
  },
  {
    label: "20px",
    value: "20",
  },
  {
    label: "24px",
    value: "24",
  },
  {
    label: "30px",
    value: "30",
  },
  {
    label: "36px",
    value: "36",
  },
  {
    label: "48px",
    value: "48",
  },
  {
    label: "60px",
    value: "60",
  },
  {
    label: "72px",
    value: "72",
  },
  {
    label: "96px",
    value: "96",
  },
  {
    label: "120px",
    value: "120",
  },
  {
    label: "144px",
    value: "144",
  },
  {
    label: "180px",
    value: "180",
  },
  {
    label: "240px",
    value: "240",
  },
  {
    label: "300px",
    value: "300",
  },
];
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const workingEnvArr = ["local", "build"];
// TODO: move to services
export const workingEnv = workingEnvArr[0]; // 0 for local, 1 for build
export const IS_LOCAL_ENV = workingEnv === "local"; // 0 for local, 1 for build
export const cacheBuster = window.cacheBuster || 1;
export const baseURL =
  window.location.protocol + "//" + window.location.hostname;
export const apiBaseURL =
  process.env.NODE_ENV !== "development"
    ? baseURL
    : process.env.REACT_APP_DEV_API_BASE_URL;

export const backendAPIBASEURL = "http://localhost:4000";

/*if(!IS_LOCAL_ENV){
    console.log=()=>{}
}*/
export const assetsURL = window.assetsURL || "designer/assets/";
export const imageAssetsUrl = assetsURL + "img/";
