import React, { useState, useContext, useEffect } from "react";
import AutoComplete from "../../shared/AutoComplete";
import { MediaIcons } from "../../utils/MediaIcons";
import { CanvasStore } from "../../canvas/store/canvasStore";
import { fabric } from "fabric";
import { fontSizeOptions } from "../../utils/common";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const Size = () => {
  const { activeCanvas } = useContext(CanvasStore);
  const options = [
    { label: "Landscape", icon: "original", value: "Landscape" },
    { label: "YouTube (16:9)", icon: "youTube", value: "YouTube (16:9)" },
    {
      label: "YouTube Short (9:16)",
      icon: "youTube",
      value: "YouTube Short (9:16)",
    },
    { label: "TikTok (9:16)", icon: "tikTok", value: "TikTok (9:16)" },
    {
      label: "Instagram Post (1:1)",
      icon: "instagramPost",
      value: "Instagram Post (1:1)",
    },
    {
      label: "Instagram Story (9:16)",
      icon: "instagramStory",
      value: "Instagram Story (9:16)",
    },
    {
      label: "Instagram Reel (9:16)",
      icon: "instagramReel",
      value: "Instagram Reel (9:16)",
    },
  ];
  const [activeFrame, setActiveFrame] = useState(options[0]);

  useEffect(() => {
    if (activeCanvas) {
      const artboard = activeCanvas.getObjects("artboard")[0];
      const frame = artboard?.activeFrameRatio || options[0].label;
      setActiveFrame(options.find((item) => item.value === frame));
    }
  }, [activeCanvas]);

  const adjustCanvasAndResizeSelection = (frameRatioString) => {
    if (
      frameRatioString === "undefined" ||
      !frameRatioString ||
      frameRatioString === activeFrame
    )
      return;
    if (!activeCanvas) {
      console.warn("Canvas not found");
      return;
    }
    const objects = activeCanvas
      .getObjects()
      .filter((item) => item.type !== "artboard");
    const artboard = activeCanvas.getObjects("artboard")[0];

    let newWidth, newHeight;

    if (
      !artboard.hasOwnProperty("originalWidth") ||
      !artboard.hasOwnProperty("originalHeight")
    ) {
      artboard.originalWidth = artboard.width;
      artboard.originalHeight = artboard.height;
    }

    if (frameRatioString === "Landscape") {
      newWidth = artboard.originalWidth;
      newHeight = artboard.originalHeight;
    } else {
      const ratio = frameRatioString.split("(")[1];
      const frameRatioParts = ratio.split(":");
      const widthRatio = parseInt(frameRatioParts[0], 10);
      const heightRatio = parseInt(frameRatioParts[1], 10);

      if (widthRatio > heightRatio) {
        newWidth = artboard.originalWidth;
        newHeight = Math.floor((newWidth * heightRatio) / widthRatio);
      } else {
        newHeight = artboard.originalHeight;
        newWidth = Math.floor((newHeight * widthRatio) / heightRatio);
      }
    }

    const minWidth = 200;
    const minHeight = 200;
    newWidth = Math.max(newWidth, minWidth);
    newHeight = Math.max(newHeight, minHeight);

    artboard.set({
      width: newWidth,
      height: newHeight,
      activeFrameRatio: frameRatioString,
    });
    activeCanvas.requestRenderAll();

    if (objects.length > 0) {
      const activeSelection = new fabric.ActiveSelection(objects);
      activeCanvas.setActiveObject(activeSelection).requestRenderAll();
      // console.log(newWidth, newHeight)
      activeCanvas.getActiveObject().set({
        left: newWidth / 2,
        top: newHeight / 2,
        originX: "center",
        originY: "center",
      });
      activeCanvas.getActiveObject().scaleToWidth(newWidth);
      // activeCanvas.getActiveObject().scaleToHeight(newHeight);

      activeCanvas.requestRenderAll();
      activeCanvas.discardActiveObject().requestRenderAll();
    }

    setActiveFrame(options.find((item) => item.value === frameRatioString));

    activeCanvas.zoomToFit(artboard);
    activeCanvas.requestRenderAll();
  };

  const renderOptionLabel = (option) => {
    return (
      <div className="text-white w-full flex items-center text-sm rounded-md cursor-pointer">
        <MediaIcons iconName={option.icon} className="mr-2" />
        <span>{option.label}</span>
      </div>
    );
  };
  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Video Size</InputLabel>
        <Select
          label="Video Size"
          value={activeFrame.label}
          onChange={(e) => {
            const selectedLabel = options.find(
              (item) => item.label === e.target.value
            ).label;
            adjustCanvasAndResizeSelection(selectedLabel);
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {renderOptionLabel(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Size;
