import { fabric } from "fabric";
import { Howl } from "howler";
const AudioContext = window.AudioContext || window.webkitAudioContext;

const props = ["src", "muted", "pan", "volume", "speed", "name"];

/**
 * Audio subclass
 * @class fabric.Audio
 * @extends fabric.Audio
 * @return {fabric.Audio} thisArg
 *
 */
fabric.Audio = class extends fabric.Rect {
  type = "audio";
  hasControls = false;
  src = "";
  fill = "#1A1A1A";
  width = 100;
  height = 120;
  originX = "left";
  originY = "top";
  pan = 0;
  speed = 1;
  sound = null;
  stateProperties = fabric.Object.prototype.stateProperties.concat(...props);

  constructor(options) {
    super(options);

    if (options) this.setOptions(options);

    this.originX = "left";
    this.originY = "top";
    this.width = 100;
    this.height = 120;

    this._isPlaying = false;
    this._isPaused = false;

    this.on("added", this._startEvents);
  }

  cacheProperties = fabric.Object.prototype.cacheProperties.concat(...props);

  _startEvents() {
    this.setElement();
  }

  async setElement(src = this.src) {
    try {
      if (!this.src) return;
      this.sound = new Howl({
        src: [this.src],
        html5: true,
      });
      this.sound.on("end", () => {
        this._isPlaying = false;
        this._isPaused = false;
        this._isEnded = true;
      });
      const audioContext = new AudioContext();
      this._audioElement = document.createElement("audio");
      this._audioElement.preload = "auto";
      this._audioElement.crossOrigin = "anonymous";

      // pass it into the audio context
      this._track = audioContext.createMediaElementSource(this._audioElement);
      this._gainNode = audioContext.createGain();

      this._pannerOptions = { pan: this.pan };
      this._pannerNode = new StereoPannerNode(
        audioContext,
        this._pannerOptions
      );

      this._track
        .connect(this._gainNode)
        .connect(this._pannerNode)
        .connect(audioContext.destination);

      this._audioElement.src = src;
      this.muted = this._audioElement.muted;
      this.volume = this._audioElement.volume;
      this._audioBuffer = await fetch(src)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => audioContext.decodeAudioData(arrayBuffer));

      this.duration = this._audioBuffer.duration;

      this.fire("audio:buffer:loaded");
      // const rawData = this._audioBuffer.getChannelData(0);

      return this;
    } catch (error) {
      console.log(error);
    }
  }
  updateWaveFormCanvas({ layerWidth }) {
    if (!this._audioBuffer) return;
    const margin = 10;
    const chunkSize = 50;

    const canvas = document.createElement("canvas");
    canvas.width = layerWidth;
    canvas.height = 24;

    // const ctx = canvas.getContext("2d");
    // const {height} = canvas;
    // const centerHeight = Math.ceil(height / 2);
    // const scaleFactor = (height - margin * 2) / 2;
    draw(
      normalizeData(
        filterData(this._audioBuffer, this.startTime, this.endTime)
      ),
      canvas
    );
    // const float32Array = this._audioBuffer.getChannelData(0);
    //
    // const array = [];
    //
    // let i = 0;
    // const length = float32Array.length;
    // while (i < length) {
    //   array.push(
    //       float32Array.slice(i, i += chunkSize).reduce(function (total, value) {
    //         return Math.max(total, Math.abs(value));
    //       })
    //   );
    // }
    //
    // canvas.width = Math.ceil(float32Array.length / chunkSize + margin * 2);
    //   ctx.strokeStyle = "black";
    //   ctx.strokeWidth = 2;
    //
    // for (let index in array) {
    //   ctx.beginPath();
    //   ctx.moveTo(margin + Number(index), centerHeight - array[index] * scaleFactor);
    //   ctx.lineTo(margin + Number(index), centerHeight + array[index] * scaleFactor);
    //   ctx.stroke();
    // }

    this.__waveFormCanvas = canvas;
  }

  setPan(pan = 0) {
    if (pan < -1 || pan > 1) pan = 0;

    this.pan = pan;
    this._pannerNode.pan.value = pan;
  }

  getElement() {
    return this._audioElement;
  }

  play() {
    if (this._isPlaying) return;
    // this._audioElement?.play();
    this._isPlaying = true;
    this._isPaused = false;
    this.sound.play();
  }

  async playFrom(time) {
    if (this._isPlaying) return;
    // this._audioElement?.play();
    this._isPlaying = true;
    this._isPaused = false;
    await this.seek(time);
    this.play();
  }

  pause() {
    this._isPlaying = false;
    this._isPaused = true;
    this.sound.pause();
    // this._audioElement?.pause();
  }

  async stop() {
    this._isPlaying = false;
    this._isPaused = false;

    await this.seek(0);
    // this._audioElement?.pause();
    this.sound.stop();
  }

  audioVolume(volume = 1) {
    if (this._audioElement) {
      const transform = {
        action: "volume",
        e: {},
        original: {
          ...fabric.util.saveObjectTransform(this),
        },
      };
      this.sound.volume(volume);
      // this._audioElement.volume = volume;
      this.volume = this._audioElement.volume;

      this.canvas.fire("object:modified", {
        target: this,
        transform,
        e: {},
        action: "volume",
      });
    }
  }
  setPlaybackSpeed(speed) {
    this.speed = speed;
    this.sound.rate(speed);
    this.canvas.fire("animate:prop:updated", { target: this });
    //
    // if (this._audioElement) {
    //   this._audioElement.playbackRate = speed;
    //   this.canvas.fire('animate:prop:updated',{target:this})
    //
    // }
  }

  muteAudio(muted = true) {
    const transform = {
      action: "mute",
      e: {},
      original: {
        ...fabric.util.saveObjectTransform(this),
      },
    };

    this.sound.mute(muted);
    // this._audioElement.muted = muted;
    this.muted = muted;

    this.canvas.fire("object:modified", {
      target: this,
      transform,
      e: {},
      action: "mute",
    });
  }

  async seek(time) {
    this._isPlaying = false;
    this._isPaused = true;
    this._isEnded = false;
    this.sound.pause();
    this.sound.seek(time);
    return false;
  }

  waitForAudioSeeked(sound = this.sound) {
    return new Promise((resolve) => {
      const handler = () => {
        resolve(true);
        sound.off("seek", handler);
      };
      sound.on("seek", handler);
    });
  }

  /**
   * @private
   * @param {CanvasRenderingContext2D} ctx Context to render on
   *
   */ /** @ts-ignore */
  _render(ctx) {
    /*
    ctx.save();
    ctx.translate(-this.width / 2, -this.height / 2);

    ctx.beginPath();
    ctx.strokeStyle = 'lightGray';
    ctx.lineWidth = 2;

    ctx.beginPath();
    ctx.moveTo(15, 0);
    ctx.lineTo(this.width, 0);
    ctx.lineTo(this.width, this.height);
    ctx.lineTo(0, this.height);
    ctx.lineTo(0, 15);
    ctx.closePath();
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(15, 0);
    ctx.lineTo(15, 15);
    ctx.lineTo(0, 15);
    ctx.stroke();

    ctx.restore();

    ctx.beginPath();
    ctx.strokeStyle = 'silver';
    ctx.lineWidth = 4;
    ctx.lineCap = 'round';
    ctx.moveTo(0, -20);
    ctx.lineTo(0, 20);
    ctx.stroke();

    ctx.beginPath();
    ctx.strokeStyle = 'silver';
    ctx.lineWidth = 4;
    ctx.lineCap = 'round';
    ctx.moveTo(-10, -15);
    ctx.lineTo(-10, 15);
    ctx.stroke();

    ctx.beginPath();
    ctx.strokeStyle = 'silver';
    ctx.lineWidth = 4;
    ctx.lineCap = 'round';
    ctx.moveTo(-20, -10);
    ctx.lineTo(-20, 10);
    ctx.stroke();

    ctx.beginPath();
    ctx.strokeStyle = 'silver';
    ctx.lineWidth = 4;
    ctx.lineCap = 'round';
    ctx.moveTo(-30, -2.5);
    ctx.lineTo(-30, 2.5);
    ctx.stroke();

    ctx.beginPath();
    ctx.strokeStyle = 'silver';
    ctx.lineWidth = 4;
    ctx.lineCap = 'round';
    ctx.moveTo(10, -15);
    ctx.lineTo(10, 15);
    ctx.stroke();

    ctx.beginPath();
    ctx.strokeStyle = 'silver';
    ctx.lineWidth = 4;
    ctx.lineCap = 'round';
    ctx.moveTo(20, -10);
    ctx.lineTo(20, 10);
    ctx.stroke();

    ctx.beginPath();
    ctx.strokeStyle = 'silver';
    ctx.lineWidth = 4;
    ctx.lineCap = 'round';
    ctx.moveTo(30, -2.5);
    ctx.lineTo(30, 2.5);
    ctx.stroke();

    ctx.beginPath();
    ctx.fillStyle = 'silver';
    ctx.font = '500 12px "aktiv-grotesk", sans-serif';
    ctx.textAlign = 'center';
    ctx.fillText('Audio', 0, 50);
    ctx.stroke();
*/
  }

  _getAudioContext() {
    return [];
  }

  /**
   * Returns object representation of an instance
   * @method toObject
   * @param {Array} [propertiesToInclude] Any properties that you might want to additionally include in the output
   * @return {Object} object representation of an instance
   */
  toObject(propertiesToInclude) {
    return this.callSuper("toObject", props.concat(propertiesToInclude));
  }
};

fabric.Audio.fromObject = function (object, callback) {
  const instanceAudio = new fabric.Audio(object);
  if (callback) {
    callback(instanceAudio);
  }
  return instanceAudio;
};

/**
 * Filters the AudioBuffer retrieved from an external source
 * @param {AudioBuffer} audioBuffer the AudioBuffer from drawAudio()
 * @returns {Array} an array of floating point numbers
 */
const filterData = (audioBuffer, startTime, endTime) => {
  startTime = startTime || 0;
  endTime = endTime || 0;
  let duration = Math.round(endTime - startTime);
  let rawData = new Float32Array(Math.round(audioBuffer.sampleRate * duration));
  audioBuffer.copyFromChannel(rawData, 0, audioBuffer.sampleRate * startTime); // We only need to work with one channel of data
  const samples = duration * 30; // Number of samples we want to have in our final data set
  const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
  const filteredData = [];
  for (let i = 0; i < samples; i++) {
    let blockStart = blockSize * i; // the location of the first sample in the block
    let sum = 0;
    for (let j = 0; j < blockSize; j++) {
      sum = sum + Math.abs(rawData[blockStart + j]); // find the sum of all the samples in the block
    }
    filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
  }
  return filteredData;
};

/**
 * Normalizes the audio data to make a cleaner illustration
 * @param {Array} filteredData the data from filterData()
 * @returns {Array} an normalized array of floating point numbers
 */
const normalizeData = (filteredData) => {
  const multiplier = Math.pow(Math.max(...filteredData), -1);
  return filteredData.map((n) => n * multiplier);
};

/**
 * Draws the audio file into a canvas element.
 * @param {Array} normalizedData The filtered array returned from filterData()
 * @returns {Array} a normalized array of data
 */
const draw = (normalizedData, canvas) => {
  // set up the canvas
  const dpr = 1;
  const padding = 0;
  canvas.width = canvas.width * dpr;
  canvas.height = (canvas.height + padding * 2) * dpr;
  const ctx = canvas.getContext("2d");
  ctx.scale(dpr, -dpr);
  ctx.translate(0, -canvas.height); // set Y = 0 to be in the middle of the canvas

  // draw the line segments
  const width = canvas.width / normalizedData.length;
  for (let i = 0; i < normalizedData.length; i++) {
    const x = width * i;
    let height = normalizedData[i] * canvas.height - padding;
    if (height < 0) {
      height = 0;
    } else if (height > canvas.height) {
      height = canvas.height;
    }
    ctx.fillStyle = "#B2EBF2";
    ctx.fillRect(x, 0, 5, height);
  }
};
