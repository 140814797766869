import { React } from "react";
import { AlertTitle, Button, Link, Snackbar, Alert } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function ShowMessage(props) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={props.showFileError}
      onClose={() => {
        props.setShowFileError(false);
      }}
      autoHideDuration={10000}
    >
      <Alert
        variant={"filled"}
        severity={props.severity}
        action={
          props.link && (
            <Button
              disableElevation
              disableRipple
              variant="outlined"
              size="small"
              sx={{
                color: "white",
                borderColor: "white",
                "&:hover": {
                  borderColor: "white", // Set the border color for hover state
                },
              }}
              href={props.link}
              target="_blank"
            >
              Upgrade
            </Button>
          )
        }
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
}
