import React, { useContext, useEffect, useState } from "react";
import { CanvasStore } from "../../canvas/store/canvasStore";
import { withModifiedEvent } from "../../canvas/utils/helper";

function PlaybackSpeed({ object }) {
  const [videoSpeed, setVideoSpeed] = useState(1);
  const { activeCanvas } = useContext(CanvasStore);

  useEffect(() => {
    if (!object) return;
    setVideoSpeed(object.speed);
  }, [object]);

  const handleSpeedChange = (speed) => {
    if (!object) return;
    withModifiedEvent(
      object,
      activeCanvas,
      () => {
        setVideoSpeed(speed);
        object.setPlaybackSpeed(speed);
        activeCanvas.requestRenderAll();
      },
      ["speed"],
      "playbackSpeedChange"
    )();
  };

  return (
    <div
      className={
        "flex-grow py-2 flex items-center rounded-[8px] px-4 border border-slate-700 border-solid shadow-sm"
      }
    >
      <span className={"text-white text-sm mr-auto"}>Speed</span>
      <div className="flex gap-2">
        <div
          className={`${
            videoSpeed === 0.5 && "active"
          } active:bg-[#eff9ff]  rounded-[8px] px-2 active:border-primary active:text-primary hover:border-primary hover:text-primary cursor-pointer border border-slate-700 border-solid shadow-sm`}
          onClick={() => handleSpeedChange(0.5)}
        >
          <span className={"text-slate-300 text-sm"}>0.5x</span>
        </div>
        <div
          className={`active:bg-[#eff9ff] ${
            videoSpeed === 1 && "active"
          } rounded-[8px] px-2 active:border-primary active:text-primary hover:border-primary hover:text-primary cursor-pointer border border-slate-700 border-solid shadow-sm`}
          onClick={() => handleSpeedChange(1)}
        >
          <span className={"text-slate-300 text-sm"}>1x</span>
        </div>
        <div
          className={`active:bg-[#eff9ff] ${
            videoSpeed === 1.5 && "active"
          } rounded-[8px] px-2 active:border-primary active:text-primary hover:border-primary hover:text-primary cursor-pointer border  border-slate-700 border-solid shadow-sm`}
          onClick={() => handleSpeedChange(1.5)}
        >
          <span className={"text-slate-300 text-sm"}>1.5x</span>
        </div>
        <div
          className={`active:bg-[#eff9ff] ${
            videoSpeed === 2 && "active"
          } rounded-[8px] px-2 active:border-primary active:text-primary hover:border-primary hover:text-primary cursor-pointer border  border-slate-700 border-solid shadow-sm`}
          onClick={() => handleSpeedChange(2)}
        >
          <span className={"text-slate-300 text-sm"}>2x</span>
        </div>
      </div>
    </div>
  );
}

export default PlaybackSpeed;
