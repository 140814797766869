import { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";

import "@fontsource/roboto/400.css";

import ButtonWithMenu from "../components/ButtonWithMenu/ButtonWithMenu";
import ChatGPT from "../components/ChatGPT/ChatGPT";

import FetchUrl from "../components/FetchUrl/FetchUrl";
import ShowMessage from "../components/ShowMessage/ShowMessage";
import SpeechOptions from "../components/SpeechOptions/SpeechOptions";
import StockMedia from "../components/StockMedia/StockMedia";
import SubscribeOffer from "../components/SubscribeOffer/SubscribeOffer";
import UploadAudio from "../components/UploadAudio/UploadAudio";
import UploadMedia from "../components/UploadMedia/UploadMedia";
import UploadFile from "../components/UploadFile/UploadFile";
import EditorMenu from "../components/EditorMenu/EditorMenu";
import UserSettings from "../components/UserSettings/UserSettings";
import UserMedia from "../components/UserMedia/UserMedia";
import Translate from "../components/Translate/Translate";
import VoiceSelection from "../components/VoiceSelection/VoiceSelection";
import ShareOptions from "../components/ShareOptions/ShareOptions";

import useResponsive from "../hooks/useResponsive";
import { secondsToHMS, generateRandomString } from "../utils/utils";

import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";

// @mui
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Hidden,
  IconButton,
  Modal,
  Paper,
  Popover,
  Stack,
  Tabs,
  Tab,
  TextField,
  Tooltip,
  Typography,
  LinearProgress,
} from "@mui/material";

import { alpha, useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  onSnapshot,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";

import { analytics, db, logEvent } from "../firebase.js";
import mixpanel from "mixpanel-browser";

import { getDownloadURL, getStorage, getMetadata, ref } from "firebase/storage";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useUserAuth } from "../UserAuthContext";
import { functions, httpsCallable } from "../firebase"; // import Firebase functions instance
import PQueue from "p-queue";

// components
import Iconify from "../components/iconify";
import ProjectName from "../components/ProjectName/ProjectName";
import PlayerBar from "../components/PlayerBar/PlayerBar";

// 3P deps
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { set } from "lodash";

const storage = getStorage();

export default function EditorPage() {
  const theme = useTheme();
  const { user, isTeamMember, isUserPremium } = useUserAuth();

  const defaultFieldConfig = {
    text: "",
    voice: {},
    pitch: "default",
    speed: 1,
    emotion: "",
    audioGsUrl: "", // generate audio gs url
    audioUrl: "", // generate audio player url
    music: "", // bg music
    origMediaGsUrl: "", // uploaded media gs url
    origMediaPlayerUrl: "", // upload media player url
    videoGsUrl: "",
    videoUrl: "",
    processing: false,
  };

  const videoPlayerRef = useRef();
  const playerRef = useRef();
  const textFieldRef = useRef();

  const [fields, setFields] = useState([defaultFieldConfig]);
  const [focusedField, setFocusedField] = useState(0);
  const [moveFocusedField, setMoveFocusedField] = useState(null);

  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState(null);

  const [processing, setProcessing] = useState(false);
  const [processingDownload, setProcessingDownload] = useState(false);
  const [progress, setProgress] = useState(10);
  const [statusMessage, setStatusMessage] = useState("");

  const [cursorPosition, setCursorPosition] = useState(-1);
  const [dragBorder, setDragBorder] = useState(0);

  const [loading, setLoading] = useState(true);
  const [autoPlay, setAutoPlay] = useState(false);

  const [useSingleVoice, setUseSingleVoice] = useState(true);

  const [transcribing, setTranscribing] = useState(false);
  const [textDirection, setTextDirection] = useState("");

  const [audioPlayerUrl, setAudioPlayerUrl] = useState(null);
  const [mergedAudioGsUrl, setMergedAudioGsUrl] = useState("");
  const [mergedAudioUrl, setMergedAudioUrl] = useState("");

  // preview
  const [previewAudioClipUrl, setPreviewAudioClipUrl] = useState("");
  const [currentpreviewField, setCurrentpreviewField] = useState(null);

  //
  const [audioWithBgGsUrl, setAudioWithBgGsUrl] = useState("");
  const [audioWithBgUrl, setAudioWithBgUrl] = useState("");

  // Media type
  const [mediaType, setMediaType] = useState("");

  // Video related flags
  const [videoGsUrl, setVideoGsUrl] = useState("");
  const [videoPlayerUrl, setVideoPlayerUrl] = useState("");
  const [playVideo, setPlayVideo] = useState(false);
  const [showVideoPanel, setShowVideoPanel] = useState(false);
  const [processingVideo, setProcessingVideo] = useState(false);
  const [mergedVideoGsUrl, setMergedVideoGsUrl] = useState("");
  const [mergedVideoUrl, setMergedVideoUrl] = useState("");
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  // Language and voice options
  const [voices, setVoices] = useState([]);
  const [clonedVoices, setClonedVoices] = useState([]);
  const [languages, setLanguages] = useState([]);

  // Background music
  const [selectedbgTrack, setSelectedbgTrack] = useState("");
  const [selectedbgTrackName, setSelectedbgTrackName] = useState("");

  // addfield button click tracker
  const [userChangedFields, setUserChangedFields] = useState(false);

  // user preferences for conversion
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedVoice, setSelectedVoice] = useState("");
  const [selectedEmotion, setSelectedEmotion] = useState("");

  // User settings
  const [customWords, setCustomWords] = useState([]);

  // Editor options
  const pauseOptions = ["1s", "2s", "5s"];
  const emphasisOptions = ["strong", "moderate", "reduced"];
  const speedOptions = [0.5, 0.8, 1, 1.25, 1.5, 1.75, 2.0];
  const pitchOptions = ["Default", "Low", "Medium", "High"];

  // UI element switches
  const [openVoiceModal, setOpenVoiceModal] = useState(false); // media
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAI, setShowAI] = useState(false);
  // Show fetch url section
  const [fetchUrlSource, setFetchUrlSource] = useState("");

  // error  options
  const [error, setError] = useState(false);
  const [messageLink, setMessageLink] = useState("");

  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(
    "Something went wrong. Please try again or try refeshing"
  );

  const [severity, setSeverity] = useState("error");
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openMediaModal, setOpenMediaModal] = useState(false); // media

  // Translation
  const [openTranslateModal, setOpenTranslateModal] = useState(false);
  const [translating, setTranslating] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const isDesktop = useResponsive("up", "lg");

  // user quota
  const [searchParams] = useSearchParams();
  const [usage, setUsage] = useState(0);
  const [quota, setQuota] = useState(10000);
  const [cloningUsage, setCloningUsage] = useState(0);
  const [cloningQuota, setCloningQuota] = useState(0);
  const [transcriptionUsage, setTranscriptionUsage] = useState(0);
  const [transcriptionQuota, setTranscriptionQuota] = useState(10);
  const canTranscribe = transcriptionUsage <= transcriptionQuota;
  const maxSections = isUserPremium ? 50 : 10;

  // If customer signed up today then show offer
  const [signedUpToday, setSignedUpToday] = useState(false);

  const [showOffer, setShowOffer] = useState(false);
  const [isOverAIQuota, setIsOverAIQuota] = useState(false);

  //
  const location = useLocation();
  const navigate = useNavigate();
  const [isVideoProject, setIsVideoProject] = useState(false);
  const [showShareDialog, setshowShareDialog] = useState(false);

  // overflow menus
  const [anchorEl, setAnchorEl] = useState(null);
  const [importAnchorEl, setImportAnchorEl] = useState(null);

  const handleOverflowClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOverflowClose = () => {
    setAnchorEl(null);
  };

  const handleImportClick = (event) => {
    setImportAnchorEl(event.currentTarget);
  };

  const handleImportOverflowClose = () => {
    setImportAnchorEl(null);
  };

  //
  const [openExportAllMenu, setOpenExportAllMenu] = useState(false);
  const anchorRef = useRef(null);

  // functions
  const synthesizeAudio = httpsCallable(functions, "genaudio", {
    timeout: 120000,
  });
  const genVideoFunction = httpsCallable(functions, "genVideo", {
    timeout: 180000,
  });
  const translateFunction = httpsCallable(functions, "translate");

  const handleRemoveField = (index) => {
    // this is used to move the focus to the right field
    setMoveFocusedField(-1);

    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);

    if (index === fields.length - 1) {
      setUserChangedFields(true);
    }

    setMergedAudioGsUrl("");
    setMergedAudioUrl("");
    setAudioPlayerUrl("");
    setMergedVideoGsUrl("");
    setVideoPlayerUrl("");
  };

  /* Handle case where customer tries to navigates away before saving by
  // refreshing or closing the tab. This doesn't handle in app navigation,
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (true) {
        event.preventDefault();
        event.message = "";
      }
      console.log("handleBeforeUnload is called");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);*/

  // Load project and other pre-reqs
  useEffect(() => {
    async function loadProject() {
      const paramProjectId = searchParams.get("id");
      if (!paramProjectId) {
        console.log("no id");
        return;
      }
      // Store it for use in other parts of the app
      setProjectId(paramProjectId);

      const projectRef = doc(db, "projects", paramProjectId);
      const projSnapShot = await getDoc(projectRef);

      if (
        !projSnapShot.exists() ||
        (process.env.NODE_ENV === "production" &&
          projSnapShot.data().user !== user.email)
      ) {
        setError(true);
        return;
      }

      //const projData = projSnapShot.data();
      // console.log("reloading proj");
      setProject(projSnapShot.data());

      if (projSnapShot.data().type === "video") {
        setIsVideoProject(true);

        if (isDesktop) {
          setShowVideoPanel(true);
        }

        setMergedVideoGsUrl(projSnapShot.data().mergedVideoGsUrl);
        setMergedVideoUrl(projSnapShot.data().mergedVideoUrl);
        setVideoPlayerUrl(projSnapShot.data().mergedVideoUrl);
      }

      // For existing projects restore previous data
      if (projSnapShot.data().fields) {
        // console.log(projSnapShot.data().fields);
        setFields(projSnapShot.data().fields);
        setSelectedVoice(projSnapShot.data().selectedVoice || "");
        setSelectedbgTrack(projSnapShot.data().selectedbgTrack || "");
        setSelectedbgTrackName(projSnapShot.data().selectedbgTrackName || "");

        if (projSnapShot.data().mergedAudioUrl) {
          setAudioPlayerUrl(projSnapShot.data().mergedAudioUrl);
          setMergedAudioUrl(projSnapShot.data().mergedAudioUrl);
          setMergedAudioGsUrl(projSnapShot.data().mergedAudioGsUrl);
        }

        if (projSnapShot.data().textDirection) {
          // console.log(projSnapShot.data().textDirection);
          setTextDirection(projSnapShot.data().textDirection);
        }

        if (projSnapShot.data().use_single_voice) {
          // console.log(projSnapShot.data().textDirection);
          setUseSingleVoice(projSnapShot.data().use_single_voice);
        }

        // make sure this is at the end as it returns the language used in
        // subsequent functions
        if (projSnapShot.data().selectedLanguage) {
          setSelectedLanguage(projSnapShot.data().selectedLanguage);
          return projSnapShot.data().selectedLanguage;
        }
      }
    }

    async function loadLanguages(projectLang) {
      const q = query(
        collection(db, "languages"),
        orderBy("order"),
        orderBy("language")
      );
      const querySnapshot = await getDocs(q);
      setLanguages(querySnapshot.docs.map((doc) => ({ ...doc.data() })));

      if (projectLang) {
        setSelectedLanguage(projectLang);
      } else {
        setSelectedLanguage(querySnapshot.docs[0].data().code);
      }
    }

    async function loadClonedVoices() {
      const q = query(collection(db, "clonedvoices"));
      const querySnapshot = await getDocs(q);
      setClonedVoices(
        querySnapshot.docs.map((doc) => ({ voice: doc.data().voice }))
      );
    }

    const loadData = async () => {
      const projectLang = await loadProject();
      await loadLanguages(projectLang);
      await loadClonedVoices();
    };

    loadData();
    /*async function loadBgTracks() {
      const q = query(collection(db, "bgtracks"), orderBy("display"));
      const querySnapshot = await getDocs(q);
      setBgTracks(querySnapshot.docs.map((doc) => ({ ...doc.data() })));
    }
    loadBgTracks();*/
  }, []);

  // used to track customer usage
  useEffect(() => {
    let snapDoc = doc(db, "customers", user.uid);

    isTeamMember().then((team) => {
      if (team) {
        snapDoc = doc(db, "teams", team);
      }

      const unsubscribe = onSnapshot(snapDoc, (docSnapshot) => {
        if (docSnapshot.exists()) {
          // console.log("reloading customer...");
          const userData = docSnapshot.data();
          setUsage(userData.usage);
          setQuota(userData.quota);
          setCloningUsage(userData.cloning_usage);
          setCloningQuota(userData.cloning_quota);
          setTranscriptionUsage(userData.transcription_usage);
          setTranscriptionQuota(userData.transcription_quota);
          setIsOverAIQuota(userData.ai_usage >= userData.ai_quota);

          // if signed up today, provide a special offer
          const currentTime = new Date();
          const timeDifference = currentTime - userData.signup;
          const twentyFourHoursInMillis = 24 * 60 * 60 * 1000;
          if (timeDifference <= twentyFourHoursInMillis) {
            setSignedUpToday(true);
          }

          if (userData.custom_words) {
            setCustomWords(userData.custom_words);
            // forceRegen();
          }

          setLoading(false);
        } else {
          // Document doesn't exist
          handleShowMessage("Something went wrong");
        }
      });

      // Clean up the subscription when the component unmounts
      return () => {
        unsubscribe();
      };
    });
  }, []);

  // Periodically saves customer project
  /*useEffect(() => {
    const saveStateInterval = setInterval(() => {
      // Your code to save state to Firebase here
      console.log('State saved!');
    }, 1 * 60 * 1000); // Save state every 1 minutes (5 * 60 * 1000 milliseconds)

    // Clean up the interval when the component is unmounted or when the dependencies change
    return () => clearInterval(saveStateInterval);
  }, []);*/

  const forceRegen = async () => {
    const newFields = [...fields];
    await Promise.all(
      newFields.map(async (field) => {
        field.audioGsUrl = "";
        field.audioUrl = "";
        field.audioDuration = "";
      })
    );
    setFields(newFields);
  };

  const handleSpeedChange = (speed, index) => {
    const newFields = [...fields];
    newFields[index].speed = speed;
    newFields[index].audioGsUrl = "";
    newFields[index].audioDuration = "";
    newFields[index].audioUrl = "";
    setFields(newFields);
  };

  const handlePitchChange = (pitch, index) => {
    const newFields = [...fields];
    newFields[index].pitch = pitch.toLowerCase();
    newFields[index].audioGsUrl = "";
    newFields[index].audioDuration = "";
    newFields[index].audioUrl = "";
    setFields(newFields);
  };

  const handleVoiceChange = (voice, index) => {
    if (fields.length === 0) {
      return;
    }
    const newFields = [...fields];
    newFields[index].voice = voice;
    newFields[index].audioGsUrl = "";
    newFields[index].audioDuration = "";
    newFields[index].audioUrl = "";
    newFields[index].error = false;
    if (isVideoProject) {
      newFields[index].videoUrl = "";
    }
    setFields(newFields);
    setMergedAudioGsUrl("");
    setMergedAudioUrl("");
    setAudioPlayerUrl("");
    handleEmotionChange("", index);
  };

  const handleEmotionChange = (emotion, index) => {
    const newFields = [...fields];
    newFields[index].emotion = emotion;
    newFields[index].audioGsUrl = "";
    newFields[index].audioDuration = "";
    newFields[index].audioUrl = "";
    setFields(newFields);
  };

  const handleUploadedAudio = async (filename) => {
    const projectRef = doc(db, "projects", projectId);
    await updateDoc(doc(db, "projects", projectId), {
      transcribing: true,
    });

    console.log("transcribing");
    setTranscribing(true);

    handleShowMessage(
      "Transcribing audio. This can take a few minutes.",
      "info"
    );

    const transcribeFunction = httpsCallable(functions, "transcribe", {
      timeout: 180000,
    });
    try {
      // console.log("transc");
      transcribeFunction({ filename, projectId, selectedVoice })
        .then(() => {
          console.log("Transcribe function submitted successfully.");
        })
        .catch((error) => {
          console.error("Error calling transcribeFunction:", error);
        });

      const unsubscribe = onSnapshot(projectRef, (snapshot) => {
        if (snapshot.exists()) {
          const projData = snapshot.data();
          if (projData.fields) {
            setFields(projData.fields);
            setTranscribing(projData.transcribing);
          }
        }
      });

      // Clean up by unsubscribing when component unmounts or when projectData is received
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.log(error);

      //handleShowMessage();
    }
  };

  const handleFieldMediaChange = (url, previewUrl, index, id) => {
    // console.log(`field: ${index} ${url} ${previewUrl}`);
    const newFields = [...fields];
    newFields[index].origMediaGsUrl = url;
    newFields[index].origMediaPlayerUrl = previewUrl;
    if (id) {
      newFields[index].id = id;
    }
    setFields(newFields);
  };

  // checks if the input text contains any non-whitespace characters.
  const isTextValid = (text) => {
    return !/^\s*$/.test(text);
  };

  const handleTextDirection = (text) => {
    // Use the Unicode Bidirectional Algorithm to check if the text is RTL
    const rtlRegex = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
    //console.log(rtlRegex.test(text));
    rtlRegex.test(text) ? setTextDirection("rtl") : setTextDirection("");
  };

  const handleFieldChange = (text, index) => {
    //newlines should be handled as new
    if (text === "\n") {
      return;
    }

    handleTextDirection(text);

    const newFields = [...fields];
    newFields[index].error = !isTextValid(text);
    newFields[index].text = text;
    newFields[index].audioGsUrl = "";
    newFields[index].audioDuration = "";
    newFields[index].audioUrl = "";
    if (isVideoProject) {
      newFields[index].videoGsUrl = "";
      newFields[index].videoUrl = "";
    }
    //finally remove merged audio or video
    setMergedAudioGsUrl("");
    setMergedVideoGsUrl("");
    setAudioPlayerUrl("");
    setVideoPlayerUrl("");
    setFields(newFields);
  };

  const setPreviewAudioUrl = (index, audioUrl) => {
    const newFields = [...fields];
    newFields[index].audioUrl = audioUrl;
    setFields(newFields);
  };

  const setGcsAudioUrl = (index, gcsUrl, audioDuration = 0) => {
    const newFields = [...fields];
    newFields[index].audioGsUrl = gcsUrl;
    newFields[index].audioDuration = audioDuration;
    setFields(newFields);
  };

  const setGcsVideoUrl = (index, gcsUrl) => {
    const newFields = [...fields];
    newFields[index].videoGsUrl = gcsUrl;
    setFields(newFields);
  };

  const setPreviewProcessing = (index, value) => {
    const newFields = [...fields];
    //console.log(`setting ${index} to ${value}`);
    newFields[index].processing = value;
    setFields(newFields);
  };

  const setFieldAsError = (index) => {
    const newFields = [...fields];
    //console.log(`setting ${index} to ${value}`);
    newFields[index].error = true;
    setFields(newFields);
  };

  const allSectionsReady = () => {
    let ready = true;

    fields.some((field, index) => {
      if (
        field.error ||
        (field.voice.cloud === "custom" &&
          !IsClonedVoiceValid(field.voice.voice))
      ) {
        ready = false;
        field.error = true;
        setFocusedField(index);
        return true; // Exit the loop early
      }
      return false;
    });

    return ready;
  };

  const translateContent = async () => {
    const newFields = [...fields];
    const translatedFields = await Promise.all(
      newFields.map(async (field) => {
        if (field.text) {
          const translatedText = await translateField(field);
          return { ...field, text: translatedText };
        }
        return field;
      })
    );
    setFields(translatedFields);
    setOpenTranslateModal(false);
    setTranslating(false);
  };

  const translateField = async (field) => {
    const result = await translateFunction({
      inputText: field.text,
      targetLang: selectedLanguage,
    });
    return result.data.translation[0];
  };

  const translateSampleText = async (text) => {
    const result = await translateFunction({
      inputText: text,
      targetLang: selectedLanguage,
    });
    return result.data.translation[0];
  };

  const hasQuota = () => {
    // console.log("check quota");
    let overQuota = false;

    let totalCharacters = 0;
    for (const field of fields) {
      if (field.text) {
        totalCharacters += field.text.length;
      }
    }
    //console.log(totalCharacters, quota, usage);

    if (
      selectedVoice.type === "clone" &&
      totalCharacters > cloningQuota - cloningUsage
    ) {
      // console.log(totalCharacters, cloningQuota, cloningUsage);
      overQuota = true;
    }

    if (selectedVoice.type !== "clone" && totalCharacters > quota - usage) {
      //console.log(totalCharacters, quota, usage);
      overQuota = true;
    }

    if (overQuota) {
      setShowOffer(true);
      setProcessing(false);
    }
    return !overQuota;
  };

  const handleMerge = async () => {
    if (!allSectionsReady()) {
      handleShowMessage(
        "Some fields have errors. Please correct them before proceeding"
      );
      return;
    }

    if (!hasQuota()) {
      return;
    }

    setProcessing(true);
    const audios = [];

    setStatusMessage("Generating audio...");

    if (selectedVoice.source === "custom") {
      // 3 is the limit by 11labs
      const queue = new PQueue(3);

      fields.forEach((field, index) => {
        if (!field.audioGsUrl && isTextValid(field.text)) {
          queue.add(() => generateAudio(index, false));
        }
      });

      await queue.onIdle();
    } else {
      await Promise.all(
        fields.map(async (field, index) => {
          if (!field.audioGsUrl && isTextValid(field.text)) {
            await generateAudio(index, false);
          }
        })
      );
    }

    let errorEncountered = false;
    fields.forEach((field) => {
      if (field.error) {
        errorEncountered = true;
        return;
      }
      if (field.audioGsUrl) {
        audios.push(field.audioGsUrl);
      }
    });

    if (errorEncountered) {
      console.error("One of the field has error. Stop processing.");
      return;
    }

    // console.log(audios)
    let audioUrl = "";
    let audioGsUrl = ""; // used for passing to the bg music
    if (fields.length === 1) {
      audioGsUrl = fields[0].audioGsUrl;
      setMergedAudioGsUrl(audioGsUrl);

      audioUrl = fields[0].audioUrl;
      setMergedAudioUrl(audioUrl);
      // console.log(`play: ${audioUrl}`);
    } else {
      const mergeDialogueFunction = httpsCallable(functions, "mergeDialogue", {
        timeout: 300000,
      });
      try {
        setStatusMessage("Creating audio file...");
        // console.log(selectedbgTrack);
        const result = await mergeDialogueFunction({ audios });
        audioGsUrl = result.data;
        setMergedAudioGsUrl(audioGsUrl);

        audioUrl = await getDownloadURL(ref(storage, audioGsUrl));
        setMergedAudioUrl(audioUrl);
      } catch (error) {
        console.log(error);
        setProcessing(false);
        handleShowMessage();
      }
    }

    // merge background with generated audio
    if (selectedbgTrack) {
      setStatusMessage("Remixing music...");
      setProgress(80);
      audioUrl = await addBgTrack(audioGsUrl);
      setMergedAudioUrl(audioUrl);
    }

    //console.log(`play: ${audioUrl}`);
    setAudioPlayerUrl(audioUrl);
    setAutoPlay(true);
    setProcessing(false);
    setStatusMessage(""); // reset
    await saveProject(false, audioGsUrl, audioUrl);
  };

  const addBgTrack = async (contentGsUrl, isVideo = false) => {
    try {
      const addBackgroundMusicFunction = httpsCallable(functions, "addbgmusic");

      // console.log(selectedbgTrack);
      const result = await addBackgroundMusicFunction({
        content: contentGsUrl,
        isVideo: isVideo,
        bgtrack: selectedbgTrack,
      });

      let gsUrl = result.data;
      setAudioWithBgGsUrl(gsUrl);
      const audioUrl = await getDownloadURL(ref(storage, gsUrl));
      setAudioWithBgUrl(audioUrl);
      return audioUrl;
    } catch (error) {
      handleShowMessage();
      console.log(error);
      setProcessing(false);
    }
  };

  const handleDownload = async (includeClips = false, includeSrt = false) => {
    setProcessingDownload(true);

    await updateDoc(doc(db, "projects", projectId), {
      export: true,
    });

    // Processing for multiple sections
    const audios = [];

    // await saveProject();

    let url = "";

    if (!includeClips) {
      if (isVideoProject) {
        url = mergedVideoUrl;
      } else {
        // Special handle for the case where there is only one section
        if (fields.length === 1) {
          url = selectedbgTrack ? audioWithBgUrl : fields[0].audioUrl;
        } else {
          url = selectedbgTrack ? audioWithBgUrl : mergedAudioUrl;
        }
        mixpanel.track(selectedbgTrack ? "DL audio with bg" : "DL audio only", {
          projectId: projectId,
        });

        // console.log(`Downloading: ${url}`);
      }
    } else {
      // Add the files to zip
      // Add merged file. Keep this order

      fields.forEach((field) => {
        if (field.audioGsUrl) {
          audios.push(field.audioGsUrl);
        }
      });

      if (selectedbgTrack) {
        audios.push(audioWithBgGsUrl);
      } else {
        audios.push(mergedAudioGsUrl);
      }

      const zipFilesFunction = httpsCallable(functions, "zipAudio");
      try {
        const result = await zipFilesFunction({ audios });
        url = result.data.url;
      } catch (error) {
        console.log(error);
        setProcessingDownload(false);
        handleShowMessage();
      }
    }

    mixpanel.track(
      selectedbgTrack
        ? "DL audio with bg music and clips"
        : "DL audio and clips",
      {
        projectId: projectId,
      }
    );

    // Set the download filename
    const fileName = includeClips
      ? "acoust.zip"
      : isVideoProject
      ? "acoust.mp4"
      : "acoust.mp3";

    TriggerDownload(fileName, url);
  };

  const TriggerDownload = (fileName, url) => {
    // Initiate the download
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";

    // Handle the AJAX success event
    xhr.onload = function () {
      if (xhr.status === 200) {
        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(xhr.response);
        link.download = fileName;

        // Simulate a click on the anchor element
        link.click();

        // Clean up the temporary URL object
        window.URL.revokeObjectURL(link.href);
        setProcessingDownload(false);
      }
    };
    // Send the AJAX request
    xhr.send();
  };

  // Creates a banner with relevant message. Defaults to error
  const handleShowMessage = (
    message = "Something went wrong. Please contact us if the issue persists",
    sev = "error",
    link
  ) => {
    setShowMessage(true);
    setMessage(message);
    setSeverity(sev);
    setMessageLink(link);
  };

  const processCustomWords = (text) => {
    customWords.forEach((word) => {
      var regex = new RegExp("\\b" + word.word + "\\b", "g");
      if (word.ipa) {
        text = text.replace(
          regex,
          `<phoneme alphabet="ipa" ph="${word.sayAs}">${word.word}</phoneme>`
        );
      } else {
        text = text.replace(regex, word.sayAs);
      }
    });
    // console.log(text)
    return text;
  };

  const replaceForSSML = (text) => {
    // replace :pause: with ssml tag
    let updatedText = text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&apos;");

    var regex = new RegExp("\\B" + ":pause:" + "\\B", "g");
    updatedText = updatedText.replace(regex, '<break time="1s" />');
    return updatedText;
  };

  // Used for voice preview only
  const generateVoicePreviewAudio = async (
    previewVoice,
    emotion,
    previewMode
  ) => {
    let extractedText;

    if (fields[0].text) {
      // for preview only use first 10 words to avoid abuse
      extractedText = fields[0].text.split(/\s+/).slice(0, 10).join(" ");
    } else {
      extractedText = await translateSampleText(
        "Enter text to preview this voice with your content."
      );
    }

    //console.log(selectedLanguage);
    if (customWords) {
      extractedText = processCustomWords(extractedText);
    }

    if (
      fields[0].voice.cloud !== "oa" ||
      fields[0].voice.display_name.includes("Journey") ||
      fields[0].voice.display_name.includes("Casual")
    ) {
      extractedText = replaceForSSML(extractedText);
    }

    try {
      const result = await synthesizeAudio({
        ...defaultFieldConfig,
        voice: previewVoice.voice,
        source: previewVoice.cloud,
        langCode: previewVoice.code,
        text: extractedText,
        emotion: emotion,
        mode: previewMode,
      });

      let gsUrl = result.data.gcsUrl;
      let audioUrl = await getDownloadURL(ref(storage, gsUrl));

      return audioUrl;
    } catch (error) {
      console.log(error);
      handleShowMessage();
    }
    // Remove once timer based save is available
    await saveProject();
  };

  // Generates the audio for a given section.
  const generateAudio = async (index) => {
    await saveProject();

    var field = fields[index];
    //console.log(field)
    let text = field.text;

    if (customWords) {
      text = processCustomWords(text);
    }

    if (
      fields[index].voice.cloud !== "oa" &&
      !fields[index].voice.voice.includes("Journey") &&
      !fields[index].voice.voice.includes("Casual")
    ) {
      text = replaceForSSML(text);
    }

    // console.log(text);
    setPreviewProcessing(index, true);

    try {
      const result = await synthesizeAudio({
        voice: field.voice.voice,
        source: field.voice.cloud,
        langCode: field.voice.code,
        text: text,
        music: field.music,
        emotion: field.emotion,
        pitch: field.pitch,
        speed: field.speed,
      });

      let gsUrl = result.data.gcsUrl;
      let duration = result.data.duration;

      setGcsAudioUrl(index, gsUrl, duration);

      if (fields.length === 1) {
        setMergedAudioGsUrl(gsUrl);
      }

      let audioUrl = await getDownloadURL(ref(storage, gsUrl));
      setPreviewAudioUrl(index, audioUrl);
      setPreviewProcessing(index, false);

      logEvent(analytics, "button_click", {
        buttonName: "Generate Audio",
      });

      return audioUrl;
    } catch (error) {
      console.log(error);
      if (error.message === "Sentences too long") {
        handleShowMessage(
          "Text contains sentences that are too long. Consider splitting them"
        );
        setFieldAsError(index);
      } else {
        handleShowMessage();
      }
    } finally {
      setProcessing(false);
      setPreviewProcessing(index, false);
    }
  };

  const handleAddField = (index) => {
    // console.log(selectedVoice)

    // this is used for focusing the right field
    setMoveFocusedField(1);

    let newFields = [...fields];

    const newField = {
      ...defaultFieldConfig,
      voice: selectedVoice,
    };

    if (selectedEmotion) {
      newField.emotion = selectedEmotion;
    }

    if (index >= 0 && index < newFields.length) {
      newFields.splice(index + 1, 0, newField);
    } else {
      newFields = [...fields, newField];
    }

    // console.log(newFields)
    setFields(newFields);
    setUserChangedFields(true);
  };

  const handleCloseUploadModal = () => setOpenUploadModal(false);
  const handleOpenUploadModal = () => setOpenUploadModal(true);

  const handleCloseOfferModal = () => setShowOffer(false);

  const handleCloseSettingsModal = () => setOpenSettingsModal(false);
  const handleOpenSettingsModal = () => setOpenSettingsModal(true);

  const handleCloseTranslateModal = () => setOpenTranslateModal(false);
  const handleOpenTranslateModal = () => setOpenTranslateModal(true);

  const handlePaste = (event, index) => {
    const pastedData = event.clipboardData.getData("text");
    handleContent(pastedData, index);

    // For a new project, we want the focus be the first field
    if (fields.length === 0) {
      setFocusedField(0);
    }

    event.preventDefault();
  };

  const handleContent = (data, index = 0, overWrite = false) => {
    // On paste, regenerate audio
    setMergedAudioGsUrl("");
    setMergedAudioUrl("");
    setAudioPlayerUrl("");

    handleTextDirection(data);

    const maxChars = 2000;
    const lines = data.split("\n");
    const nonEmptyLines = lines.filter((line) => line.trim() !== "");

    if (nonEmptyLines.length > maxSections) {
      handleShowMessage(
        `Oops! You've reached maximum sections limit (${maxSections})  ${
          isUserPremium ? "" : "for free accounts"
        }. Try combining sections together`,
        "warning"
      );

      nonEmptyLines.length = maxSections;
    }
    // Initialize an array to store the chunks
    const chunks = [];

    // Iterate over non-empty lines and split if necessary
    for (const line of nonEmptyLines) {
      if (line.length > maxChars) {
        // Split the line into chunks of up to maxChars characters
        const words = line.split(/\s+/);
        let currentChunk = "";

        for (const word of words) {
          if ((currentChunk + " " + word).length <= maxChars) {
            currentChunk += (currentChunk === "" ? "" : " ") + word;
          } else {
            chunks.push(currentChunk);
            currentChunk = word;
          }
        }

        // Add the last chunk
        if (currentChunk !== "") {
          chunks.push(currentChunk);
        }
      } else {
        chunks.push(line);
      }
    }

    //console.log(`processing: ${lines.length} lines`);

    const combinedFields = overWrite ? [defaultFieldConfig] : [...fields];
    //console.log(combinedFields.length);
    chunks.forEach((line, lineIndex) => {
      const fieldIndex = index + lineIndex;
      //console.log(fieldIndex);
      if (lineIndex === 0) {
        //console.log(`pasting to ${fieldIndex}`);
        const field = document.getElementById(`ssml-editor_${fieldIndex}`);
        const startPos = field.selectionStart;
        const endPos = field.selectionEnd;

        let newText;

        if (overWrite) {
          newText = line;
        } else {
          if (startPos >= fields[fieldIndex].text.length - 1) {
            newText = fields[fieldIndex].text.substring(0, startPos) + line;
          } else {
            newText =
              fields[fieldIndex].text.substring(0, startPos) +
              line +
              fields[fieldIndex].text.substring(
                endPos,
                fields[fieldIndex].text.length
              );
          }
        }
        combinedFields[fieldIndex] = {
          ...combinedFields[fieldIndex],
          text: newText,
          audioGsUrl: "",
          audioUrl: "",
          voice: selectedVoice,
          error: !isTextValid(line),
          // mediaGsUrl: fields[fieldIndex].media,
          // mediaUrl: fields[fieldIndex].mediaUrl,
        };

        if (lines.length === 1) {
          // console.log("resetting cursor position");
          // console.log(startPos, endPos, line.length);
          const newCursorPosition = startPos + line.length;

          //console.log(newCursorPosition);
          setCursorPosition(newCursorPosition);
        }
      } else {
        combinedFields.splice(fieldIndex, 0, {
          ...defaultFieldConfig,
          text: line,
          voice: selectedVoice,
          error: !isTextValid(line),
        });
      }
    });

    // console.log(combinedFields);
    setFields(combinedFields);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      handleAddField(index);
    }
  };

  const handleFieldFocus = (index) => {
    setFocusedField(index);
  };

  const handleFieldBlur = (index) => {
    setFocusedField(null);
  };

  const saveProject = async (alert = false, audioGsUrl, audioUrl) => {
    // console.log(fields);
    const projData = {
      fields: fields,
      selectedVoice: selectedVoice,
      selectedbgTrack: selectedbgTrack,
      selectedbgTrackName: selectedbgTrackName,
      selectedLanguage: selectedLanguage,
      textDirection,
    };

    if (mergedVideoUrl) {
      projData.mergedVideoUrl = mergedVideoUrl;
      projData.mergedVideoGsUrl = mergedVideoGsUrl;
    }

    if (mergedAudioUrl) {
      projData.mergedAudioUrl = audioUrl || mergedAudioUrl;
      projData.mergedAudioGsUrl = audioGsUrl || mergedAudioGsUrl;
    }

    // console.log(projData);
    // console.log(projectId);
    await updateDoc(doc(db, "projects", projectId), projData);

    if (alert) {
      handleShowMessage("Project saved", "success");
    }
  };

  const showShareProject = async () => {
    setshowShareDialog(!showShareDialog);
  };

  // Sets the focus to the right text box when a user adds or deletes a field
  useEffect(() => {
    let textBoxId;

    if (focusedField !== null) {
      // Focus one plus or minus the current field based on moveFocusedField value
      textBoxId = `ssml-editor_${focusedField + moveFocusedField}`;
    } else {
      // Focus the last field when adding a new field at the end
      textBoxId = `ssml-editor_${fields.length - 1}`;
    }

    // When removing the last field, move the focus to the new last field
    if (focusedField >= fields.length - 1) {
      textBoxId = `ssml-editor_${fields.length - 1}`;
    }

    const textBoxToFocus = document.getElementById(textBoxId);

    if (textBoxToFocus) {
      textBoxToFocus.focus();
    }
    setMoveFocusedField(null);
  }, [fields.length, focusedField]);

  useEffect(() => {
    if (textFieldRef.current && cursorPosition >= 0) {
      textFieldRef.current.setSelectionRange(cursorPosition, cursorPosition);
      setCursorPosition(-1);
    }
  }, [cursorPosition]);

  const handleCloseVoiceModal = () => {
    setOpenVoiceModal(false);
  };

  const handleOpenVoiceModal = (index) => {
    setCurrentIndex(index);
    setOpenVoiceModal(true);
  };

  const handleAudioEnded = () => {
    setPreviewAudioClipUrl("");
    setCurrentpreviewField(null);
  };

  const handlePreview = async (index) => {
    if (playerRef.current && !playerRef.current.audioEl.current.paused) {
      playerRef.current.audioEl.current.pause();
      setPreviewAudioClipUrl("");
      if (index === currentpreviewField) {
        return;
      }
    }
    setCurrentpreviewField(index);
    console.log("previewing...");

    if (!hasQuota()) {
      return;
    }

    // console.log(fields[index]);

    let url = isVideoProject ? fields[index].videoUrl : fields[index].audioUrl;

    if (!url) {
      url = isVideoProject
        ? await generateVideo(index, true)
        : await generateAudio(index, true);
    }

    if (isVideoProject) {
      setVideoPlayerUrl(url);
      setPlayVideo(true);
    } else {
      // console.log(url);
      //setAudioPlayerUrl(url);
      setPreviewAudioClipUrl(url);
    }
    mixpanel.track("Preview section", {
      projectId: projectId,
      voice: selectedVoice,
    });

    await saveProject();
  };

  const handleMediaModal = (mediaType, index = null) => {
    setCurrentIndex(index);
    setMediaType(mediaType);
    setOpenMediaModal(true);
  };

  const closeMediaModal = () => setOpenMediaModal(false);

  const handleAIButton = () => {
    setShowAI(!showAI);
  };

  const handleShowFetchUrl = (value) => {
    handleImportOverflowClose();
    setFetchUrlSource(value);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleMediaChange = (name, gsUrl, previewUrl) => {
    if (mediaType === "video") {
      setVideoGsUrl(gsUrl);
      setVideoPlayerUrl(previewUrl);
    } else if (mediaType === "audio") {
      handleBgMusicChange(name, gsUrl);
    }
  };

  const handleBgMusicChange = (name, url) => {
    setSelectedbgTrack(url);
    setSelectedbgTrackName(name);
    setMergedAudioGsUrl("");
    setMergedAudioUrl("");
    setAudioPlayerUrl("");
    //setVideoPlayerUrl(previewUrl);
  };

  const handleVideoPanelToggle = () => {
    setShowVideoPanel(!showVideoPanel);
  };

  // Combines the video clips into the final video.
  const handleVideoClipMerge = async () => {
    setProcessing(true);
    const videos = [
      //'gs://textspeech-55a09.appspot.com/RWqpAn03Uwba1hUnDASSECIJMqC3/videos/7dfc9f0f-7896-4768-a743-b8dca9af95a8.mp4',
      //'gs://textspeech-55a09.appspot.com/RWqpAn03Uwba1hUnDASSECIJMqC3/videos/7dfc9f0f-7896-4768-a743-b8dca9af95a8.mp4'
      //'gs://textspeech-55a09.appspot.com/RWqpAn03Uwba1hUnDASSECIJMqC3/videos/49ad5094-d952-4011-ba6a-dfcddb30aaa9.mp4',
      //'gs://textspeech-55a09.appspot.com/RWqpAn03Uwba1hUnDASSECIJMqC3/videos/49ad5094-d952-4011-ba6a-dfcddb30aaa9.mp4'
    ];

    await Promise.all(
      fields.map(async (field, index) => {
        if (!field.videoGsUrl) {
          await generateVideo(index, false);
        }
      })
    );

    fields.map(async (field) => {
      // console.log(field);
      videos.push(field.videoGsUrl);
    });

    const mergedBlob = new Blob(videos);
    const mergedUrl = URL.createObjectURL(mergedBlob);
    setMergedVideoUrl(mergedUrl);

    //mergedVideo.src = mergedUrl;

    console.log("pausing");
    return;
    // console.log(audios)
    let videoUrl = "";
    setStatusMessage("Merging clips...");

    const mergeVideosFunction = httpsCallable(functions, "mergeVideos", {
      timeout: 300000,
    });
    let gsUrl;

    try {
      const result = await mergeVideosFunction({ videos });
      gsUrl = result.data;
      setMergedVideoGsUrl(gsUrl);
      videoUrl = await getDownloadURL(ref(storage, gsUrl));
      setMergedVideoUrl(videoUrl);
      // console.log(videoUrl);
      setProcessing(false);
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
      setStatusMessage("");
      await saveProject();
    }

    if (selectedbgTrack) {
      setStatusMessage("Remixing music...");
      videoUrl = await addBgTrack(gsUrl, true);
    }

    setVideoPlayerUrl(videoUrl);
    setStatusMessage(""); // reset

    console.log(videoUrl);
  };

  // Used to merge imported video with generated audio
  const handleVideoMerge = async () => {
    setProcessingVideo(true);
    const genVideoFunction = httpsCallable(functions, "mergettsvideo");

    try {
      const vresult = await genVideoFunction({
        audio: mergedAudioGsUrl,
        video: videoGsUrl,
        text: "",
      });

      const generatedVideoGsUrl = vresult.data;
      setVideoGsUrl(generatedVideoGsUrl);
      setMergedVideoGsUrl(generatedVideoGsUrl);
      const videoUrl = await getDownloadURL(ref(storage, generatedVideoGsUrl));
      // console.log(videoUrl);
      setVideoPlayerUrl(videoUrl);
      setMergedVideoUrl(videoUrl);
      return videoUrl;
      //setPreviewProcessing(index, false);
    } catch (error) {
      console.log(error);
      handleShowMessage("Something went wrong");

      //setPreviewProcessing(index, false);
    } finally {
      setProcessingVideo(false);
    }
  };

  const handleVideoEnd = () => {
    setPlayVideo(false);
    setIsVideoPlaying(false);
  };

  // Create TTS and merge it with video clip
  const generateVideo = async (index, playPreview = true) => {
    var field = fields[index];

    setPreviewProcessing(index, true);
    // const genVideoFunction = httpsCallable(functions, "genVideo");
    //let gsUrl =
    //  "gs://textspeech-55a09.appspot.com/RWqpAn03Uwba1hUnDASSECIJMqC3/tts/audio_bbe1217a-866c-4d8f-b0b6-d791bfd4604c.mp3";
    //let uvideoGsUrl = 'gs://textspeech-55a09.appspot.com/RWqpAn03Uwba1hUnDASSECIJMqC3/media/pexels-pat-whelen-5738706-1920x1080-24fps.mp4'

    // console.log(field);
    let videoUrl;
    try {
      if (!field.audioGsUrl) {
        setStatusMessage(`Generating speech for clip ${index + 1} ...`);
        await generateAudio(index, false);
      }

      setStatusMessage(`Generating clip ${index + 1}...`);
      setPreviewProcessing(index, true);

      const vresult = await genVideoFunction({
        audio: field.audioGsUrl,
        video:
          field.origMediaGsUrl === ""
            ? field.origMediaPlayerUrl
            : field.origMediaGsUrl,
        id: field.id,
        text: field.text,
      });

      if (playPreview) {
        setStatusMessage("Generating video preview...");
      }
      const videoGsUrl = vresult.data;
      setGcsVideoUrl(index, videoGsUrl);
      videoUrl = await getDownloadURL(ref(storage, videoGsUrl));
      // console.log(`get video url: ${videoUrl}`);
      setPreviewVideoUrl(index, videoUrl);

      if (playPreview) {
        setVideoPlayerUrl(videoUrl);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setStatusMessage("");
      setPreviewProcessing(index, false);
    }
    return videoUrl;
  };

  const setPreviewVideoUrl = (index, videoUrl) => {
    const newFields = [...fields];
    newFields[index].videoUrl = videoUrl;
    setFields(newFields);
  };

  const resetFields = () => {
    console.log("reset to:", selectedVoice);
    setFields([{ ...defaultFieldConfig, voice: selectedVoice }]);
  };

  if (error) {
    return (
      <>
        <Helmet>
          <title> Text to Speech Editor | Acoust AI</title>
        </Helmet>
        <Container>
          <Typography variant="subtitle1">
            Unable to access specified project. Either it doesn't exist or you
            don't have access
          </Typography>
        </Container>
      </>
    );
  }

  const handleBgDelete = () => {
    setSelectedbgTrack("");
    setSelectedbgTrackName("");
    setMergedAudioGsUrl("");
    setMergedAudioUrl("");
    setAudioPlayerUrl("");
  };

  const handleOnDragStart = () => {
    setDragBorder(0.5);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newFields = [...fields];
    const [removed] = newFields.splice(result.source.index, 1);
    newFields.splice(result.destination.index, 0, removed);
    setFields(newFields);
    setFocusedField(result.destination.index);
    setDragBorder(0);
  };

  const IsClonedVoiceValid = (voice) => {
    if (!clonedVoices.length) {
      return;
    }
    const index = clonedVoices.findIndex((v) => v.voice === voice);
    return index !== -1;
  };

  if (loading || !project) {
    return (
      <Stack mt={2}>
        <CircularProgress size={20} />
      </Stack>
    );
  }

  return (
    <>
      <Helmet>
        <title> Text to Speech Editor | Acoust AI</title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          mt={1}
          mb={1}
        >
          <ProjectName pName={project.name} id={projectId} />

          <Tooltip title="Save project" placement="top">
            <IconButton onClick={() => saveProject(true)}>
              <Iconify icon="material-symbols:save-outline" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Share" placement="top">
            <Button
              onClick={() => showShareProject(true)}
              variant="text"
              startIcon={<Iconify icon="material-symbols:share" />}
            >
              <div>
                Share
                <Iconify color="#B71C1C" icon="ic:outline-fiber-new" />
              </div>
            </Button>
          </Tooltip>
        </Stack>

        <EditorMenu
          showAI={showAI}
          setShowAI={setShowAI}
          setFetchUrlSource={setFetchUrlSource}
          handleMediaModal={handleMediaModal}
          handleOpenTranslateModal={handleOpenTranslateModal}
          handleOpenSettingsModal={handleOpenSettingsModal}
          transcribing={transcribing}
          handleOpenUploadModal={handleOpenUploadModal}
          projectId={projectId}
          handleContent={handleContent}
          hideImport={
            project.fields?.length > 1 || project?.fields?.[0]?.text?.length
          }
        />

        {/* Music info */}
        {selectedbgTrackName && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            spacing={1}
            mt={1}
          >
            <Typography sx={{ fontSize: "10px", color: "text.secondary" }}>
              Music:
            </Typography>
            <Chip
              size="small"
              color="primary"
              variant="outlined"
              //onClick={() => handleOpenVoiceModal(index)}
              label={selectedbgTrackName}
              onDelete={handleBgDelete}
            />
          </Stack>
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={1}
          mt={1}
        >
          <Button
            disabled={fields.length === 1}
            size="small"
            variant="outlined"
            onClick={resetFields}
            startIcon={<Iconify icon="ic:baseline-clear" />}
          >
            Clear All
          </Button>
        </Stack>

        {/* END Music info */}
        <Stack mt={1}>
          {showAI && (
            <ChatGPT
              handleContent={handleContent}
              //isContentLoading={isContentLoading}
              //setIsContentLoading={setIsContentLoading}
              isOverAIQuota={isOverAIQuota}
            />
          )}

          {/* UI for fetch url functionality */}
          {fetchUrlSource && (
            <FetchUrl
              handleUpdateText={handleContent}
              handleShowFetchUrl={handleShowFetchUrl}
              handleShowMessage={handleShowMessage}
              fetchUrlSource={fetchUrlSource}
              setFetchUrlSource={setFetchUrlSource}
              canTranscribe={canTranscribe}
            />
          )}
        </Stack>
        {/* Sections UI */}
        <Stack
          sx={{
            minHeight: "32vh",
            border: `${dragBorder}px dashed ${theme.palette.primary.main}`,
          }}
        >
          <DragDropContext
            onDragStart={handleOnDragStart}
            onDragEnd={handleOnDragEnd}
          >
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((field, index) => (
                    <Draggable
                      draggableId={`draggable-${index}`}
                      index={index}
                      key={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Stack
                            key={index}
                            sx={{
                              border: (theme) =>
                                `solid 1px ${theme.palette.divider}`,
                              borderBottom: "0.5px solid #616161",
                              //borderRadius: focusedField === index ? "4px" : "0",
                            }}
                          >
                            {/* Settings stack */}
                            {focusedField === index && (
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Stack
                                  width="100%"
                                  alignItems="center"
                                  direction="row"
                                  justifyContent="space-between"
                                  //paddingLeft={1}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    flexGrow={1}
                                  >
                                    <Box>
                                      <Iconify icon="mdi:drag" />
                                    </Box>
                                    <Tooltip
                                      title="Select voice"
                                      placement="top"
                                      arrow
                                    >
                                      <Chip
                                        size="small"
                                        color="primary"
                                        variant="outlined"
                                        sx={{
                                          color: "white",
                                          border: "2px double transparent",
                                          backgroundImage:
                                            "linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)), linear-gradient(90deg, rgba(93,26,138,1) 10%, rgba(202,10,10,1) 80%, rgba(233,145,21,1) 100%)",
                                          backgroundOrigin: "border-box",
                                          backgroundClip:
                                            "padding-box, border-box",
                                          boxShadow:
                                            "rgba(var(--primary-color), 0.5) 0px 0px 20px 0px",
                                        }}
                                        onClick={() =>
                                          handleOpenVoiceModal(index)
                                        }
                                        onDelete={() =>
                                          handleOpenVoiceModal(index)
                                        }
                                        deleteIcon={
                                          <Iconify icon="eva:chevron-down-outline" />
                                        }
                                        label={
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                          >
                                            <Iconify icon="mingcute:voice-fill" />
                                            &nbsp;
                                            <>
                                              {`${field.voice.display_name}`}

                                              {field.emotion &&
                                                ` · ${field.emotion}`}
                                              <Typography
                                                sx={{
                                                  fontSize: "12px",
                                                  color: "text.secondary",
                                                }}
                                              >
                                                {isDesktop
                                                  ? field.voice.cloud === "oa"
                                                    ? "multilingual"
                                                    : field.voice.language
                                                  : null}
                                              </Typography>
                                            </>
                                          </Stack>
                                        }
                                      />
                                    </Tooltip>
                                    &nbsp;
                                    {field.voice.cloud !== "custom" && (
                                      <ButtonWithMenu
                                        labelText="Insert Pause"
                                        inputText={field.text}
                                        index={index}
                                        updateText={handleFieldChange}
                                        icon="material-symbols:auto-read-pause-outline"
                                        tip="Add pause at the current cursor position"
                                        voice={field.voice}
                                      />
                                    )}
                                    <SpeechOptions
                                      labelText="Speed"
                                      options={speedOptions}
                                      index={index}
                                      handleSpeedChange={handleSpeedChange}
                                      icon="fluent:top-speed-24-regular"
                                      tip="Change the speed of the generated speech"
                                    />
                                    {field.voice.cloud !== "oa" &&
                                      field.voice.voice &&
                                      field.voice.voice.includes("Journey") &&
                                      field.voice.voice.includes("Casual") && (
                                        <SpeechOptions
                                          labelText="Pitch"
                                          options={pitchOptions}
                                          index={index}
                                          handlePitchChange={handlePitchChange}
                                          icon="ri:sound-module-fill"
                                          tip="Change the pitch of the generated speech"
                                        />
                                      )}
                                  </Stack>

                                  <Stack direction="row" alignItems="center">
                                    <Tooltip
                                      title="Remove paragraph"
                                      arrow
                                      placement="top"
                                    >
                                      <span>
                                        <IconButton
                                          disabled={fields.length === 1}
                                          sx={{
                                            color: "#942407",
                                          }}
                                          onClick={() =>
                                            handleRemoveField(index)
                                          }
                                        >
                                          <Iconify icon="material-symbols:delete-outline" />
                                        </IconButton>
                                      </span>
                                    </Tooltip>

                                    <Tooltip
                                      title="Preview section"
                                      arrow
                                      placement="top"
                                    >
                                      <span>
                                        <LoadingButton
                                          variant="text"
                                          loading={fields[index].processing}
                                          disabled={
                                            isVideoProject
                                              ? !fields[index].text ||
                                                !fields[index]
                                                  .origMediaPlayerUrl
                                              : !fields[index].text
                                          }
                                          onClick={() => {
                                            handlePreview(index);
                                          }}
                                          startIcon={
                                            <Iconify
                                              icon={
                                                previewAudioClipUrl &&
                                                currentpreviewField === index // reset when clip is ended
                                                  ? "material-symbols:pause"
                                                  : "octicon:play"
                                              }
                                            />
                                          }
                                        >
                                          <Typography
                                            variant="subtitle2"
                                            sx={{
                                              fontSize: "10px",
                                            }}
                                          >
                                            {(isDesktop &&
                                              secondsToHMS(
                                                field.audioDuration
                                              )) ||
                                              "Preview"}
                                          </Typography>
                                        </LoadingButton>
                                      </span>
                                    </Tooltip>
                                  </Stack>
                                </Stack>
                              </Stack>
                            )}

                            <Stack direction="row">
                              <Box flexGrow={1}>
                                <TextField
                                  inputRef={textFieldRef}
                                  variant="standard"
                                  key={index}
                                  id={`ssml-editor_${index}`}
                                  placeholder={
                                    index === 0 && fields.length === 1
                                      ? "Enter or paste text here. You can also import documents, text from websites and audio files using our import options"
                                      : null
                                  }
                                  multiline
                                  fullWidth
                                  focused
                                  autoFocus
                                  minRows={fields.length === 1 ? 5 : 1}
                                  //maxRows={fields.length === 1 ? 20 : 10}
                                  value={field.text}
                                  InputProps={{
                                    disableUnderline:
                                      field.text.length >= 2000 || field.error
                                        ? false
                                        : true,
                                  }}
                                  inputProps={{
                                    maxLength: 2000,
                                    style: {
                                      fontSize: 14,
                                      fontFamily: "Roboto",
                                    },
                                  }}
                                  helperText={
                                    field.text.length >= 2000
                                      ? `${field.text.length}/2000`
                                      : field.error && field.text === ""
                                      ? "Enter text or delete empty sections"
                                      : field.voice.cloud === "custom" &&
                                        field.error
                                      ? "Voice Clone not found"
                                      : null
                                  }
                                  error={
                                    field.text.length >= 2000 || field.error
                                  }
                                  dir={textDirection}
                                  onChange={(e) =>
                                    handleFieldChange(e.target.value, index)
                                  }
                                  onPaste={(e) => handlePaste(e, index)}
                                  onKeyDown={(e) => handleKeyDown(e, index)}
                                  onFocus={() => handleFieldFocus(index)}
                                  // onBlur={() => handleFieldBlur(index)}
                                  sx={{
                                    paddingRight: "10px",
                                    paddingLeft: "10px",
                                    paddingBottom: "5px",
                                    bgcolor: (theme) =>
                                      alpha(theme.palette.grey[500], 0.09),
                                  }}
                                />
                              </Box>

                              {/* Video mode - media box */}
                              {isVideoProject && (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  paddingLeft={1}
                                  paddingRight={1}
                                  sx={{
                                    width: 170,
                                    height: 150,
                                    borderColor: "#d7dde1",
                                    justifyContent: "center", // Center vertically
                                    alignItems: "center", // Center horizontally
                                    borderRadius: "6px",
                                    backgroundColor: "#fff",
                                  }}
                                >
                                  {!field.origMediaPlayerUrl && (
                                    <Typography variant="caption">
                                      Select media
                                    </Typography>
                                  )}

                                  {field.origMediaPlayerUrl && (
                                    <ReactPlayer
                                      width="160px"
                                      height="80px"
                                      url={field.origMediaPlayerUrl}
                                    />
                                  )}
                                  <Tooltip title="Add video clip" arrow>
                                    <IconButton
                                      variant="outlined"
                                      sx={{ marginTop: "10px" }}
                                      onClick={() =>
                                        handleMediaModal("video", index)
                                      }
                                    >
                                      <Iconify icon="fluent-mdl2:media-add" />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              )}
                            </Stack>
                          </Stack>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Stack>
        {/* Add new block */}
        <Stack
          mt={1}
          direction="row"
          alignItems="center"
          justifyContent="space-around"
        >
          <Tooltip title="Add section" arrow>
            <Button
              size="small"
              disabled={
                fields.length >= maxSections ||
                (isVideoProject && fields.length >= 5)
              }
              variant="outlined"
              color="primary"
              onClick={handleAddField}
              startIcon={<Iconify icon="mdi:plus-box" />}
            >
              Add Section
            </Button>
          </Tooltip>
        </Stack>
        {/* Player bar */}
        <PlayerBar
          audioPlayerUrl={audioPlayerUrl}
          setAudioPlayerUrl={setAudioPlayerUrl}
          mergedAudioGsUrl={mergedAudioGsUrl}
          isVideoProject={isVideoProject}
          statusMessage={statusMessage}
          // allSectionsReady={allSectionsReady}
          enableExportAll={fields.length > 1}
          handleDownload={handleDownload}
          processing={processing}
          handleVideoClipMerge={handleVideoClipMerge}
          handleMerge={handleMerge}
          isContentReady={!processing && (mergedAudioGsUrl || mergedVideoGsUrl)}
          processingDownload={processingDownload}
          autoPlay={autoPlay}
          projectType={project.type}
          videoPlayerRef={videoPlayerRef}
          isVideoPlaying={isVideoPlaying}
          setIsVideoPlaying={setIsVideoPlaying}
          fields={fields}
          project={project}
          TriggerDownload={TriggerDownload}
          isPremiumUser={isUserPremium}
          previewAudioClipUrl={previewAudioClipUrl}
          setPreviewAudioClipUrl={setPreviewAudioClipUrl}
        />
        {/*<AudioPlayer audioUrl={audioPlayerUrl} />*/}
        {/* Video preview -- TODO hidden for now
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            position: "fixed",
            width: showVideoPanel ? "350px" : "10px",
            bottom: "0",
            //left: isDesktop ? '520px' : '0px',
            right: "0px",
            zIndex: 1,
            height: "100%",
            border: "1px solid #ccc",
          }}
        >
          {showVideoPanel ? (
            <IconButton
              color="primary"
              sx={{
                top: "80px",
                position: "fixed",
                right: "330px",
                zIndex: 2,
                borderRight: "1px solid #ccc",
                borderBottom: "1px solid #ccc",
                backgroundColor: "#fff", // Set the background color to opaque white
                ":hover": {
                  backgroundColor: "#fff",
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={handleVideoPanelToggle}
            >
              <Iconify icon="gg:chevron-right-o"></Iconify>
            </IconButton>
          ) : (
            isDesktop && (
              <Button
                variant="contained"
                sx={{
                  top: "150px",
                  transform: "rotate(270deg)",
                  position: "fixed",
                  right: isVideoProject ? "-30px" : "-40px",
                  zIndex: 2,
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
                startIcon={<Iconify icon="gg:chevron-up-o"></Iconify>}
                onClick={handleVideoPanelToggle}
              >
                {isVideoProject ? "Preview" : "Import Video"}
              </Button>
            )
          )}

          {showVideoPanel && (
            <Stack spacing={1} alignItems="center">
              {!videoPlayerUrl && (
                <Typography variant="caption">Nothing to preview</Typography>
              )}
              {(videoGsUrl || isVideoProject) && (
                <ReactPlayer
                  playing={playVideo}
                  onEnded={handleVideoEnd}
                  width={300}
                  url={videoPlayerUrl}
                  ref={videoPlayerRef}
                  controls
                />
              )}

              {!isVideoProject && (
                <LoadingButton
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleMediaModal("video")}
                  startIcon={<Iconify icon="fluent-mdl2:media-add" />}
                >
                  {videoGsUrl ? "Replace" : "Import"} video
                </LoadingButton>
              )}

              <Stack
                alignItems="center"
                justifyContent="center"
                padding={2}
                sx={{ width: "300px" }}
              >
                {videoGsUrl && (
                  <>
                    <Tooltip title="Merge TTS with Video" arrow>
                      <LoadingButton
                        color="primary"
                        variant="outlined"
                        disabled={!mergedAudioGsUrl}
                        onClick={handleVideoMerge}
                        loading={processingVideo}
                        sx={{
                          borderRadius: "50px",
                        }}
                      >
                        Merge Audio & Video
                      </LoadingButton>
                    </Tooltip>
                  </>
                )}
              </Stack>
            </Stack>
          )}
        </Box>
 */}
        {/* Dialogs */}
        <Dialog
          open={openMediaModal}
          onClose={closeMediaModal}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Music</DialogTitle>
          <DialogContent>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="Upload" />
              <Tab label="My Items" />
              {isVideoProject && <Tab label="Stock Media" />}
            </Tabs>
            {activeTab === 0 && (
              <UploadMedia
                handleMediaChange={handleMediaChange}
                handleFieldMediaChange={handleFieldMediaChange}
                closeMediaModal={closeMediaModal}
                mediaType={mediaType}
                index={currentIndex}
              />
            )}
            {activeTab === 1 && (
              <UserMedia
                handleMediaChange={handleMediaChange}
                handleFieldMediaChange={handleFieldMediaChange}
                closeMediaModal={closeMediaModal}
                mediaType={mediaType}
                index={currentIndex}
              />
            )}
            {activeTab === 2 && (
              <StockMedia
                index={currentIndex}
                handleFieldMediaChange={handleFieldMediaChange}
                closeMediaModal={closeMediaModal}
              />
            )}
          </DialogContent>
        </Dialog>
        <VoiceSelection
          openVoiceModal={openVoiceModal}
          handleCloseVoiceModal={handleCloseVoiceModal}
          setSelectedVoice={setSelectedVoice}
          setSelectedEmotion={setSelectedEmotion}
          handleVoiceChange={handleVoiceChange}
          handleEmotionChange={handleEmotionChange}
          languages={languages}
          selectedLang={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          selectedVoice={selectedVoice}
          index={currentIndex}
          numOfFields={fields.length}
          customerTextForPreview={fields.length > 0 ? fields[0].text : ""}
          generateVoicePreviewAudio={generateVoicePreviewAudio}
          mode="editor"
          projectId={projectId}
          useSingleVoice={useSingleVoice}
        />
        <Dialog
          open={openSettingsModal}
          onClose={handleCloseSettingsModal}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle mt={2}>Settings</DialogTitle>
          <DialogContent>
            <UserSettings
              openSettingsModal={openSettingsModal}
              handleCloseSettingsModal={handleCloseSettingsModal}
              selectedVoice={selectedVoice}
              forceRegen={forceRegen}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          open={openUploadModal}
          onClose={handleCloseUploadModal}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Upload Audio</DialogTitle>
          <DialogContent>
            <Typography sx={{ color: "text.secondary", fontSize: "11px" }}>
              Only English (US) is supported. Send us feedback if you would like
              additional langauges
            </Typography>
            <UploadAudio
              handleUploadedAudio={handleUploadedAudio}
              closeUploadModal={handleCloseUploadModal}
              audioType="voiceswap"
            />
          </DialogContent>
        </Dialog>
        {/* Translate modal */}
        <Translate
          languages={languages}
          translateContent={translateContent}
          setSelectedLanguage={setSelectedLanguage}
          selectedLang={selectedLanguage}
          translating={translating}
          setTranslating={setTranslating}
          openTranslateModal={openTranslateModal}
          handleCloseTranslateModal={handleCloseTranslateModal}
        />
        <Dialog
          fullWidth
          maxWidth="lg"
          open={showOffer}
          onClose={handleCloseOfferModal}
        >
          <SubscribeOffer />
        </Dialog>
        {/* Share dialog */}
        <ShareOptions
          showShareDialog={showShareDialog}
          setshowShareDialog={setshowShareDialog}
          projectId={projectId}
        ></ShareOptions>
        <ShowMessage
          showFileError={showMessage}
          setShowFileError={setShowMessage}
          severity={severity}
          message={message}
          link={messageLink}
        />
        <ReactAudioPlayer
          src={previewAudioClipUrl}
          preload="none"
          // controls
          autoPlay
          onEnded={handleAudioEnded}
          ref={playerRef}
        />
      </Container>
    </>
  );
}
