import {cdnGen} from "../libs/fileOps";
import {deepFindVideos} from "../canvas/utils/canvasUtils";
export async function waitForVideoCanPlayThrough(videoElement) {
    return new Promise((resolve) => {
        const handler = () => {
            videoElement.removeEventListener('canplaythrough', handler);
            resolve();
        };

        videoElement.addEventListener('canplaythrough', handler);
    });
}

export async function waitForVideoSeeked(videoElement) {
    return new Promise((resolve) => {
        const handler = () => {
            resolve(true);
            videoElement.removeEventListener('seeked', handler);
        };
        videoElement.addEventListener('seeked', handler);
    });
}
export function getVideoElement(url, muted = true, loop = false) {
    return new Promise(async (resolve, reject) => {
        const videoEl = document.createElement('video');
        videoEl.style.display = 'none';
        document.body.appendChild(videoEl);
        videoEl.autoPictureInPicture = false;
        videoEl.autoplay = false;
        videoEl.playsinline = true;
        videoEl.muted = muted;
        videoEl.loop = loop;
        videoEl.preload = 'auto';
        videoEl.crossOrigin = 'anonymous';

        const source = document.createElement('source');
        videoEl.appendChild(source);

        videoEl.onloadeddata = async function () {
            this.width = this.videoWidth;
            this.height = this.videoHeight;

            while (videoEl.duration === Infinity || isNaN(videoEl.duration)) {
                await new Promise((r) => setTimeout(r, 1000));
                videoEl.currentTime = 10000000 * Math.random();
            }

            videoEl.remove();
            resolve(videoEl);
        };

        videoEl.onerror = ()=>{
            reject()
        }

        source.src = cdnGen(url);
        videoEl.load();
    });
}
export async function handleRewindAllMedia({children,_objects,canvas}) {
    let objects=children||_objects;

    await Promise.all(
        objects.map(async (child) => {
            if (child.type === 'audio') {
                await child.stop();
                await child.seek(child.startTime / 1000);
            }
        }),
    );

    await Promise.all(
        deepFindVideos({children:objects}).map(async (video) => {
            await video.stopVideo();
            await video.seekVideo(video.startTime / 1000);
        }),
    );

    canvas?.requestRenderAll();
}
export async function handlePauseAllMedia(artboard) {

    await Promise.all(
        artboard.children.map(async (child) => {
            if (child.type === 'audio') {
                await child.pause();
            }
        }),
    );

    await Promise.all(
        deepFindVideos(artboard).map(async (video) => {
            await video.pauseVideo();
        })
    );

    artboard.canvas?.requestRenderAll();
}
export function shouldPlayMedia(child, currentTimeInMS) {
    //all times are in milliseconds
    let speed=child.speed||1;
    let initialTimeInMS= (child.animationStartTime||0)*1000;
    let totalDurationInMS=Math.floor(((child.endTime - child.startTime)/speed)*1000);
    return currentTimeInMS >= initialTimeInMS && currentTimeInMS <= initialTimeInMS+totalDurationInMS
}
export function generateVideoThumbnails(src,options={}){
    return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        const video = document.createElement("video");

        video.autoplay = true;
        video.muted = true;
        video.src = src;
        video.crossOrigin = "anonymous";

        const frames = [];

        video.onloadeddata = async () => {
            let ctx = canvas.getContext("2d");
            let thumbnailDimension={width:options.width|| video.videoWidth,height:options.height|| video.videoHeight}

            canvas.width = thumbnailDimension.width;
            canvas.height = thumbnailDimension.height;

            video.pause();

            /**
             * For loop here!
             */
            // let tFrames=Math.ceil(video.duration/2);
            for (let i = 0.1; i < video.duration; i+=2) {
                video.currentTime = i;
                await waitForVideoSeeked(video);
                ctx.drawImage(video, 0, 0,  canvas.width, canvas.height);
                const dataUrl = canvas.toDataURL("image/png");
                ctx.clearRect(0,0,canvas.width, canvas.height)
                const blob = await (await fetch(dataUrl)).blob();
                const blobUrl = URL.createObjectURL(blob);
                frames.push(blobUrl);
            }

            resolve(frames);
        };
    });
};
