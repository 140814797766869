import React, { useState, useRef, useEffect } from "react";
import {
  Chip,
  Dialog,
  DialogContent,
  Tooltip,
  ButtonGroup,
  Button,
  Popper,
  Paper,
  ClickAwayListener,
  FormControlLabel,
  MenuItem,
  MenuList,
  Stack,
  DialogTitle,
  Checkbox,
  Typography,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router";

import Iconify from "../iconify";

import SubscribeOffer from "../SubscribeOffer/SubscribeOffer";
import SrtGenerator from "../SrtGenerator/SrtGenerator";

import { useTheme, alpha } from "@mui/material/styles";

export default function ExportButton({
  processingDownload,
  handleDownload,
  enableExportAll,
  disable,
  projectType,
  isDesktop,
  allSectionsReady,
  fields,
  project,
  TriggerDownload,
  isPremiumUser,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [includeSrt, setIncludeSrt] = useState(false);
  const [includeAll, setIncludeAll] = useState(false);
  const navigate = useNavigate();

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSrtCheck = (event) => {
    setIncludeSrt(event.target.checked);
  };

  const handleIncludeAllCheck = (event) => {
    setIncludeAll(event.target.checked);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const handleUpgradeClick = () => {
    navigate("/billing");
  };

  return (
    <>
      <Tooltip
        title={
          disable ? "Play full audio before exporting" : "Audio can be exported"
        }
      >
        <Button
          size="small"
          variant="contained"
          disabled={disable}
          //aria-controls={open ? "split-button-menu" : undefined}
          //aria-expanded={open ? "true" : undefined}
          //aria-label="select export type"
          aria-haspopup="menu"
          onClick={handleToggle}
          startIcon={
            isDesktop ? <Iconify icon="material-symbols:download" /> : null
          }
        >
          {isDesktop ? "Export" : <Iconify icon="material-symbols:download" />}
        </Button>
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={isPremiumUser ? "md" : "lg"}
      >
        <DialogTitle>Export</DialogTitle>
        <DialogContent>
          <Paper>
            <Stack>
              <FormControlLabel
                disabled={!isPremiumUser}
                control={<Checkbox />}
                label={
                  <Stack spacing={2} direction="row">
                    <Typography>Generate subtitles (SRT) file </Typography>

                    {!isPremiumUser ? (
                      <Chip
                        label={
                          <Typography
                            sx={{ fontSize: "10px" }}
                            variant="subtitle2"
                          >
                            UPGRADE
                          </Typography>
                        }
                        size="small"
                        onClick={handleUpgradeClick}
                        variant="contained"
                        sx={{
                          color: "#ff6f01",
                          backgroundColor: alpha("#d55d00", 0.3),
                        }}
                      ></Chip>
                    ) : null}
                  </Stack>
                }
                onChange={handleSrtCheck}
              />
              <FormControlLabel
                control={<Checkbox />}
                disabled={!isPremiumUser}
                label={
                  <Stack spacing={2} direction="row">
                    <Typography>
                      Include each section as a separate audio file
                    </Typography>

                    {!isPremiumUser ? (
                      <Chip
                        label={
                          <Typography
                            sx={{ fontSize: "10px" }}
                            variant="subtitle2"
                          >
                            UPGRADE
                          </Typography>
                        }
                        size="small"
                        onClick={handleUpgradeClick}
                        variant="contained"
                        sx={{
                          color: "#ff6f01",
                          backgroundColor: alpha("#d55d00", 0.3),
                        }}
                      ></Chip>
                    ) : null}
                  </Stack>
                }
                onChange={handleIncludeAllCheck}
              />

              <Stack
                direction="row"
                justifyContent={
                  !isPremiumUser && fields[0].voice.cloud === "custom"
                    ? "center"
                    : "flex-end"
                }
                spacing={1}
                mt={4}
              >
                {includeSrt && (
                  <SrtGenerator
                    fields={fields}
                    allSectionsReady={allSectionsReady}
                    TriggerDownload={TriggerDownload}
                    project={project}
                  ></SrtGenerator>
                )}
                {!isPremiumUser && fields[0].voice.cloud === "custom" ? (
                  <Button disabled variant="outlined">
                    Voice cloning requires a subscription to enable downloads
                  </Button>
                ) : (
                  <LoadingButton
                    variant="contained"
                    loading={processingDownload}
                    onClick={() => handleDownload(includeAll)}
                  >
                    Download
                  </LoadingButton>
                )}
              </Stack>
              {!isPremiumUser && (
                <Stack>
                  <SubscribeOffer tableOnly></SubscribeOffer>
                </Stack>
              )}
            </Stack>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
}
