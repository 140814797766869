import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FormatAlignCenterRoundedIcon from "@mui/icons-material/FormatAlignCenterRounded";
import FormatAlignLeftRoundedIcon from "@mui/icons-material/FormatAlignLeftRounded";
import FormatAlignRightRoundedIcon from "@mui/icons-material/FormatAlignRightRounded";
import FormatLineSpacingRoundedIcon from "@mui/icons-material/FormatLineSpacingRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import {
  Box,
  Button,
  IconButton,
  Switch,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import Slider from "../shared/Slider";

import { useTheme } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { CanvasStore } from "../canvas/store/canvasStore";
import canvasActions from "../canvas/utils/canvasActions";
import { withModifiedEvent } from "../canvas/utils/helper";
import ColorPickerModal from "../color/ColorPicker";
import SpacingModal from "../components/SpacingModal";
import FontFamilyDropdown from "../shared/AutoComplete/fontFamilyDropDown";
import FontSizeDropdown from "../shared/AutoComplete/fontSizeDropDown";
import Color from "../shared/Color";
import PageHeader from "../shared/PageHeader";
import { fontFamilyOptions, fontSizeOptions } from "../utils/common";
import Animation from "./Animation";
import { set } from "lodash";

const EditText = () => {
  const theme = useTheme();
  const router = useNavigate();
  const location = useLocation();

  const [activeText, setActiveText] = useState(null);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const { activeCanvas, activeObject } = useContext(CanvasStore);
  const [textTitle, setTextTitle] = useState("");
  const [fontFamily, setFontFamily] = useState(null);
  const [fontSize, setFontSize] = useState(null);
  const [fill, setFill] = useState("");
  const [fontWeight, setFontWeight] = useState("");
  const [alignment, setAlignment] = useState("");
  const [lineHeight, setLineHeight] = useState(1);
  const [italic, setItalic] = useState(false);
  const [bold, setBold] = useState(false);
  const [spacing, setSpacing] = useState(0);
  const [useStroke, setUseStroke] = useState(false);
  const [strokeSize, setStrokeSize] = useState(1);
  const [strokeColor, setStrokeColor] = useState("#ff0000");
  const [strokeColorPickerVisible, setStrokeColorPickerVisible] =
    useState(false);

  const [showSpacingModal, setShowSpacingModal] = useState(false);
  const [animationPanel, setAnimationPanel] = useState(false);
  const [editTextPanel, setEditTextPanel] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    handleActiveObjectChanged(activeObject);
  }, [activeObject]);

  const handleActiveObjectChanged = (activeObject) => {
    if (!activeObject) {
      setTextTitle("");
      setFontFamily(null);
      setFontSize(null);
      setFill("");
      setFontWeight("");
      setAlignment("");
      setLineHeight(1);
      setItalic(false);
      setBold(false);
      setSpacing(0);
    } else if (activeObject?.type === "i-text") {
      setTextTitle(activeObject.text);
      setFontFamily(
        fontFamilyOptions.find(
          (font) => font.fontFamily === activeObject.fontFamily
        )
      );
      setFontSize(
        fontSizeOptions.find((item) => item.value === activeObject.fontSize)
      );
      setFill(activeObject.fill);
      setFontWeight(activeObject.fontWeight);
      setAlignment(activeObject.alignment);
      setLineHeight(activeObject.lineHeight);
      setItalic(activeObject.fontStyle === "italic" ? true : false);
      setBold(activeObject.fontWeight === "bold" ? true : false);
      setSpacing(activeObject.spacing);
      setStrokeColor(activeObject.stroke);
      setStrokeSize(activeObject.strokeWidth);
      setUseStroke(activeObject.strokeWidth > 0 ? true : false);
    }
  };

  const handleColorChange = (color) => {
    if (!activeObject) return;
    withModifiedEvent(
      activeObject,
      activeCanvas,
      () => {
        activeObject.set({ fill: color });
        setFill(color);
        activeCanvas.requestRenderAll();
      },
      ["fill"],
      "textFillChange"
    )();
  };

  const toggleColorPicker = () => {
    setColorPickerVisible(!colorPickerVisible);
  };

  const toggleStrokeColorPicker = () => {
    setStrokeColorPickerVisible(!strokeColorPickerVisible);
  };

  const backRouter = () => {
    router(`/v/${id}/text/`);
  };

  const onChangeText = (e) => {
    if (!activeObject) return;
    withModifiedEvent(
      activeObject,
      activeCanvas,
      () => {
        let value = e.target.value;
        activeObject.set({ text: value });
        activeCanvas.renderAll();
        setTextTitle(value);
      },
      ["text"],
      "textChange"
    )();
  };

  const renderOptionLabel = (option) => {
    return (
      <div className="w-full flex items-center text-sm select-none focus:outline-none bg-transparent text-gray-700 px-4 py-3 box-sizing border-box h-auto leading-4 rounded-md cursor-pointer hover:bg-blue-5">
        <span>{option.value}</span>
      </div>
    );
  };

  const handleFontFamily = ({ fontFamily: selectedFontFamily }) => {
    if (!activeObject) return;
    withModifiedEvent(
      activeObject,
      activeCanvas,
      () => {
        activeObject.set({ fontFamily: selectedFontFamily });
        activeCanvas.requestRenderAll();
        setFontFamily(
          fontFamilyOptions.find(
            (font) => font.fontFamily == selectedFontFamily
          )
        );
      },
      ["fontFamily"],
      "fontFamilyChange"
    )();
  };

  const handleFontSize = ({ value: fontSize }) => {
    if (!activeObject || !fontSize) return;
    withModifiedEvent(
      activeObject,
      activeCanvas,
      () => {
        activeObject.set({ fontSize: fontSize });
        activeCanvas.requestRenderAll();
        setFontSize(fontSizeOptions.find((item) => item.value == fontSize));
      },
      ["fontSize"],
      "fontSizeChange"
    )();
  };

  const handleTextStyle = (property) => {
    if (!activeObject) return;

    if (property === "Bold") {
      let newFontWeight =
        activeObject.fontWeight === "bold" ? "normal" : "bold";
      withModifiedEvent(
        activeObject,
        activeCanvas,
        () => {
          activeObject.set({ fontWeight: newFontWeight });
          setBold(newFontWeight === "bold" ? true : false);
          activeCanvas.requestRenderAll();
        },
        ["bold"],
        "textBoldChange"
      )();
    }

    if (property === "Italic") {
      let newFontStyle =
        activeObject.fontStyle === "italic" ? "normal" : "italic";
      withModifiedEvent(
        activeObject,
        activeCanvas,
        () => {
          activeObject.set({ fontStyle: newFontStyle });
          setItalic(newFontStyle === "italic" ? true : false);
          activeCanvas.requestRenderAll();
        },
        ["italic"],
        "textItalicChange"
      )();
    }

    if (property === "Stroke") {
      let newFontStyle = {
        fill: "#000",
        stroke: "red",
        strokeWidth: 1,
        paintFirst: "stroke", // stroke behind fill
      };

      withModifiedEvent(
        activeObject,
        activeCanvas,
        () => {
          activeObject.set(newFontStyle);
          //setItalic(newFontStyle === "italic" ? true : false);
          activeCanvas.requestRenderAll();
        },
        ["italic"],
        "textItalicChange"
      )();
    }
  };
  const deleteText = () => {
    if (activeObject) {
      canvasActions.remove(activeObject);
      activeCanvas.discardActiveObject();
      activeCanvas.renderAll();
      router(`/v/${id}/text/`);
    }
  };

  const handleAlignment = (align) => {
    if (!activeObject) return;
    withModifiedEvent(
      activeObject,
      activeCanvas,
      () => {
        activeObject.set({ textAlign: align });
        activeCanvas.requestRenderAll();
        setAlignment(align);
      },
      ["textAlign"],
      "textAlignChange"
    )();
  };

  const handleStrokeSwitch = (event) => {
    setUseStroke(event.target.checked);
    if (event.target.checked) {
      handleStokeColorChange("red");
      handleStokeSliderChange(1);
    } else {
      handleStokeColorChange(null);
      handleStokeSliderChange(0);
    }
  };

  const handleStokeSliderChange = (size) => {
    setStrokeSize(size);
    if (!activeObject) return;
    withModifiedEvent(
      activeObject,
      activeCanvas,
      () => {
        activeObject.set({ strokeWidth: size });
        activeCanvas.requestRenderAll();
      },
      ["strokeWidth"],
      "textStrokeChange"
    )();
  };

  const handleStokeColorChange = (color) => {
    setStrokeColor(color);
    if (!activeObject) return;
    withModifiedEvent(
      activeObject,
      activeCanvas,
      () => {
        activeObject.set({ stroke: color });
        activeCanvas.requestRenderAll();
      },
      ["strokeColor"],
      "textStrokeColorChange"
    )();
  };

  return (
    <>
      {editTextPanel && (
        <div>
          <SpacingModal
            open={showSpacingModal}
            handleClose={() => setShowSpacingModal(false)}
          />
          <PageHeader
            heading="Edit Text"
            onClicked={() => backRouter()}
            showBackButton={true}
          />

          <TextField
            id="outlined-multiline-static"
            multiline
            rows={4}
            onChange={onChangeText}
            value={activeObject ? textTitle : ""}
            className="mt-4 w-full rounded-lg"
            sx={{
              "& .MuiInputBase-root:hover": {
                borderRadius: "8px",
              },
              "& .MuiInputBase-root": {
                borderRadius: "8px",
              },
              "& .MuiInputBase-input": {
                fontSize: "14px",
                color: "#fff",
              },
            }}
          />

          {/*<div className="flex justify-between items-center mt-6 mb-6">
            <SectionHeader heading="Styles" />
            <Button
              variant="outlined"
              sx={{
                color: "#9094a5",
                fontSize: "13px",
                border: 0,
                "&:hover": {
                  background: "#eeeef0",
                  border: 0,
                  color: theme.palette.primary.main,
                },
              }}
            >
              Save Style +
            </Button>
          </div>*/}

          <div className="mt-8 flex gap-2">
            <div className="flex-1 mt-2 flex items-center gap-2">
              <FontFamilyDropdown
                fontFamilyOptions={fontFamilyOptions}
                defaultValue={fontFamily}
                onSelected={handleFontFamily}
              />
              <FontSizeDropdown
                fontSizeOptions={fontSizeOptions}
                defaultValue={fontSize}
                onSelected={handleFontSize}
              />

              <div
                className="rounded-md border border-gray-600 cursor-pointer hover:border-primary duration-300 flex justify-center items-center w-20 h-full border-solid"
                onClick={toggleStrokeColorPicker}
              >
                <Color value={fill} className="h-8 w-8" />
                {strokeColorPickerVisible && (
                  <ColorPickerModal
                    onSelectedColor={handleColorChange}
                    onClose={() => setColorPickerVisible(false)}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="mt-3 flex gap-2">
            <div className="p-2 rounded-md flex gap-2">
              <IconButton
                className={`text-[#18191b] text-[15px] font-medium p-1 rounded-md ${
                  bold ? "bg-gray-100" : "hover:bg-gray-100"
                }`}
                onClick={() => handleTextStyle("Bold")}
              >
                <Bold />
              </IconButton>
              <IconButton
                className={`text-[#18191b] text-[15px] font-medium p-1 rounded-md ${
                  italic ? "bg-gray-100" : "hover:bg-gray-100"
                }`}
                onClick={() => handleTextStyle("Italic")}
              >
                <Italic />
              </IconButton>
            </div>

            <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.06) 0px 1px 1px",
              }}
              className="p-2 rounded-md flex gap-2 flex-1 justify-around"
            >
              <IconButton
                className={`p-1 rounded-md ${
                  alignment === "left" ? "bg-gray-100" : "hover:bg-gray-100"
                }`}
                onClick={() => handleAlignment("left")}
              >
                <FormatAlignLeftRoundedIcon className="text-grayDark" />
              </IconButton>
              <IconButton
                className={`p-1 rounded-md ${
                  alignment === "center" ? "bg-gray-100" : "hover:bg-gray-100"
                }`}
                onClick={() => handleAlignment("center")}
              >
                <FormatAlignCenterRoundedIcon className="text-grayDark" />
              </IconButton>
              <IconButton
                className={`p-1 rounded-md ${
                  alignment === "right" ? "bg-gray-100" : "hover:bg-gray-100"
                }`}
                onClick={() => handleAlignment("right")}
              >
                <FormatAlignRightRoundedIcon className="text-grayDark" />
              </IconButton>
            </div>

            <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.06) 0px 1px 1px",
              }}
              className="p-2 rounded-md flex gap-2"
            >
              <IconButton
                className="  flex items-center gap-0  p-1 hover:bg-gray-100 rounded-md"
                onClick={() => setShowSpacingModal(true)}
              >
                <FormatLineSpacingRoundedIcon className="text-grayDark" />
                <KeyboardArrowDownRoundedIcon className="text-grayDark w-4 h-4" />
              </IconButton>
            </div>
          </div>
          <div className="mt-3 gap-2 ">
            <Box
              className={
                "py-2  items-center rounded-[8px] px-4 border border-slate-700 border-solid shadow-sm"
              }
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <span className={"font-semibold text-sm text-gray-100"}>
                  Text Outline
                </span>
                <span>
                  <Switch checked={useStroke} onChange={handleStrokeSwitch} />
                </span>
              </Stack>

              <div className={"w-full"}>
                <div
                  className={
                    "flex items-center justify-between flex-grow mt-4 mb-2"
                  }
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    sx={{
                      width: useStroke ? "90%" : "100%",
                      minHeight: "50px",
                    }}
                  >
                    <Typography sx={{ fontSize: "11px" }}>Size</Typography>
                    <Slider
                      disabled={!useStroke}
                      size="small"
                      value={strokeSize}
                      onChange={(event, newValue) =>
                        handleStokeSliderChange(newValue)
                      }
                      step={1}
                      min={1}
                      max={10}
                      aria-labelledby="stroke-size-slider"
                      unit=""
                    />
                  </Stack>

                  {useStroke && (
                    <div
                      className="rounded-md border border-gray-600 cursor-pointer hover:border-primary duration-300 flex justify-center items-center w-10 h-10 border-solid ml-2"
                      onClick={toggleColorPicker}
                    >
                      <Color value={strokeColor} className="h-8 w-8" />
                      {colorPickerVisible && (
                        <ColorPickerModal
                          onSelectedColor={handleStokeColorChange}
                          onClose={() => setColorPickerVisible(false)}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </div>
          {/*  <div className="mt-3 flex gap-2">
            <RectangularButton>
              <WbSunnyRoundedIcon className="text-gray-500" />
              Styles
            </RectangularButton>
            <RectangularButton
              onClick={() => {
                setAnimationPanel(true);
                setEditTextPanel(false);
              }}
            >
              <AnimationRoundedIcon className="text-gray-500" />
              Animations
            </RectangularButton>
          </div>

         
          <div className="mt-3">
            <AnimationStartEndControl object={activeObject} />
          </div>
*/}
          <div className="mt-3">
            <Button
              fullWidth
              variant="outlined"
              startIcon={<DeleteOutlinedIcon fontSize={"small"} />}
              onClick={deleteText}
              sx={{
                color: "#fff", // Text color
                borderColor: "#942407", // Border color
                backgroundColor: "#942407", // Background color
                "&:hover": {
                  backgroundColor: "#7a1b03", // Darker red when hovered
                  borderColor: "#7a1b03",
                },
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      {animationPanel && (
        <Animation
          onClose={() => {
            setAnimationPanel(false);
            setEditTextPanel(true);
          }}
        />
      )}
    </>
  );
};

export default EditText;

const Bold = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#5C5E65"
    >
      <path
        d="M6.17773 20.3091L6.17773 4.59998L12.4675 4.59998C13.6232 4.59998 14.5872 4.77128 15.3593 5.1139C16.1315 5.45651 16.7119 5.93208 17.1005 6.54061C17.4892 7.14402 17.6835 7.83947 17.6835 8.62698C17.6835 9.24061 17.5607 9.7801 17.3153 10.2454C17.0698 10.7057 16.7323 11.0841 16.3028 11.3807C15.8784 11.6722 15.3926 11.8793 14.8454 12.002L14.8454 12.1554C15.4437 12.181 16.0036 12.3497 16.5252 12.6616C17.0519 12.9736 17.4789 13.4108 17.8062 13.9733C18.1335 14.5307 18.2971 15.1955 18.2971 15.9676C18.2971 16.8011 18.09 17.5452 17.6758 18.1997C17.2667 18.8492 16.6607 19.3631 15.8579 19.7415C15.0551 20.1199 14.0656 20.3091 12.8894 20.3091L6.17773 20.3091ZM9.49906 17.5938L12.2067 17.5938C13.1323 17.5938 13.8073 17.4173 14.2318 17.0645C14.6562 16.7065 14.8684 16.231 14.8684 15.6378C14.8684 15.2031 14.7636 14.8196 14.5539 14.4872C14.3443 14.1548 14.0451 13.894 13.6565 13.7048C13.2729 13.5156 12.8153 13.421 12.2834 13.421L9.49906 13.421L9.49906 17.5938ZM9.49906 11.1736L11.9613 11.1736C12.4164 11.1736 12.8204 11.0943 13.1732 10.9358C13.5312 10.7722 13.8124 10.542 14.017 10.2454C14.2266 9.94885 14.3315 9.59346 14.3315 9.17925C14.3315 8.61163 14.1295 8.15396 13.7255 7.80623C13.3266 7.45851 12.759 7.28464 12.0227 7.28464L9.49906 7.28464L9.49906 11.1736Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

const Italic = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#5C5E65"
    >
      <path
        d="M15.4008 4.59998L11.5928 20.3091H9.22267L13.0306 4.59998L15.4008 4.59998Z"
        fill="currentColor"
      ></path>
      <path
        d="M17.8008 4.59998L17.3226 6.50896L10.1524 6.50896L10.6306 4.59998L17.8008 4.59998Z"
        fill="currentColor"
      ></path>
      <path
        d="M14.621 18.4009L14.1429 20.3098H6.97266L7.45083 18.4009H14.621Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};
