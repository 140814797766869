import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// @mui
import {
  Button,
  Container,
  CircularProgress,
  Link,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { analytics, logEvent } from "../firebase.js";

import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";

import { useUserAuth } from "../UserAuthContext";
import { useParams } from "react-router-dom";

import { doc, getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../firebase.js";

import Iconify from "../components/iconify";

import PlayerBar from "../components/PlayerBar/PlayerBar";

// ----------------------------------------------------------------------

// Used by error state
const StyledErrorContent = styled("div")(({ theme }) => ({
  position: "relative",
  top: "30vh",
}));

const StyledContent = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: "30vh",
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function StreamPage() {
  const { id } = useParams();
  const [audioPlayerUrl, setAudioPlayerUrl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadStream = async () => {
      try {
        const q = query(
          collection(db, "projects"),
          where("public_link", "==", id)
        );
        const querySnapshot = await getDocs(q);

        const projectDocumentSnapshot = querySnapshot.docs[0];
        if (!projectDocumentSnapshot.exists()) {
          console.log("No such document!");
          return;
        }

        if (projectDocumentSnapshot.data().fields.length === 1) {
          setAudioPlayerUrl(projectDocumentSnapshot.data().audioUrl);
        } else if (projectDocumentSnapshot.data().mergedAudioUrl) {
          setAudioPlayerUrl(projectDocumentSnapshot.data().mergedAudioUrl);
        } else {
          console.log("Audio does not exist");
        }
      } catch (error) {
        console.error("Error loading document:", error);
      }
    };
    loadStream().then(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <StyledContent>
        <Container>
          <Stack mt={2}>
            <CircularProgress size={20} />
          </Stack>
        </Container>
      </StyledContent>
    );
  }

  if (!audioPlayerUrl) {
    return (
      <StyledErrorContent>
        <Container>
          <Stack
            alignItems="center"
            minHeight={200}
            sx={{
              backgroundImage: "url(/bird.svg)",
              backgroundSize: "contain", // You can adjust this based on your needs
              backgroundPosition: "bottom", // You can adjust this based on your needs
              backgroundRepeat: "no-repeat",
              height: "20%",
            }}
          ></Stack>
          <Stack
            alignItems="center"
            alignContent="center"
            justifyContent="center"
            mt={3}
          >
            <Typography> hmm, we couldn't locate the audio</Typography>
            <Typography> Check the link and try again</Typography>
          </Stack>
        </Container>
      </StyledErrorContent>
    );
  }

  return (
    <>
      <Helmet>
        <title> Stream | Acoust </title>
      </Helmet>

      <Container>
        <Stack>
          <Paper elevation={10}>
            <PlayerBar
              audioPlayerUrl={audioPlayerUrl}
              mergedAudioGsUrl={""}
              isVideoProject={false}
              statusMessage={""}
              allSectionsReady={true}
              enableExportAll={false}
              handleDownload={null}
              processing={false}
              handleVideoClipMerge={null}
              handleMerge={null}
              isContentReady={true}
              processingDownload={false}
              autoPlay={false}
              projectType={"audio"}
              videoPlayerRef={null}
              isVideoPlaying={false}
              setIsVideoPlaying={null}
              fields={[]}
              project={null}
              TriggerDownload={null}
              isPremiumUser={false}
              mode="stream"
            />
          </Paper>
        </Stack>
      </Container>
    </>
  );
}
