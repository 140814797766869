import React, { useContext, useEffect, useState } from "react";
import PageHeader from "../../shared/PageHeader";
import { CanvasStore } from "../../canvas/store/canvasStore";
import AnimationStartEndControl from "../../shared/AnimationStartEndControl";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined";
import FlipButton from "../../shared/Flip";
import { Button, IconButton, TextField, Tooltip } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import OpacityControl from "../../shared/OpacityControl";
import RotationControl from "../../shared/RotationControl";
import VolumeControl from "../../shared/VolumeControl";
import PlaybackSpeed from "../../shared/PlaybackSpeed";
import canvasActions from "../../canvas/utils/canvasActions";
import { useNavigate } from "react-router";

const EditVideo = () => {
  const { activeObject, activeCanvas, projectInfo } = useContext(CanvasStore);
  const [videoName, setVideoName] = useState(null);
  const router = useNavigate();

  useEffect(() => {
    if (!activeObject) return;
    setVideoName(activeObject.name || activeObject.custom?.name);
  }, [activeObject]);

  const flipHorizontal = () => {
    if (!activeObject) {
      console.log("no selection");
      return;
    }
    activeObject.flipX = !activeObject.flipX;
    activeCanvas.requestRenderAll();
  };

  const flipVertical = () => {
    if (!activeObject) return;
    activeObject.flipY = !activeObject.flipY;
    activeCanvas.requestRenderAll();
  };

  const handleDelete = () => {
    if (!activeObject) return;
    canvasActions.remove(activeObject);
    if (activeObject.type === "image") {
      activeCanvas.remove(activeObject);
    }
    activeCanvas.requestRenderAll();
    router(`/v/${projectInfo.id}/media/`);
  };

  return (
    <div>
      <PageHeader
        heading="Edit Media"
        subHeading={videoName}
        showBackButton={false}
      />

      {!activeObject ? (
        <div>No video object selected.</div>
      ) : (
        <div className={"flex flex-col gap-2"}>
          {activeObject.type === "video" && (
            <>
              <PlaybackSpeed object={activeObject} />
              <VolumeControl object={activeObject} />
              <div
                className={
                  "border border-b my-7 border-solid rounded border-slate-700"
                }
              />
            </>
          )}
          <div>
            <OpacityControl value={100} object={activeObject} />
          </div>
          <div className={"flex gap-2"}>
            <RotationControl object={activeObject} />

            <FlipButton type={"Horizontal"} onClick={flipHorizontal} />

            <FlipButton type={"Vertical"} onClick={flipVertical} />
          </div>
          <div
            className={
              "border border-b my-7 border-solid rounded border-slate-700"
            }
          />
          {activeObject.type === "video" && (
            <AnimationStartEndControl object={activeObject} />
          )}
          {/* <div
            className={
              "hover:border-primary hover:text-primary cursor-pointer gap-3  py-2 flex items-center rounded-[8px] px-4 border border-slate-700 border-solid  shadow-sm"
            }
          >
            <InsertLinkOutlinedIcon
              sx={{ color: "white" }}
              style={{ transform: "rotate(90deg)" }}
            />
            <span className={"text-white text-sm"}>Detach Audio</span>
          </div>*/}
          <div>
            {/*<div
              className={
                "flex-grow hover:border-primary hover:text-primary cursor-pointer gap-3  py-2 flex items-center rounded-[8px] px-4 border border-slate-700 border-solid  shadow-sm"
              }
            >
              <FlipCameraAndroidOutlinedIcon sx={{ color: "white" }} />
              <span className={"text-white text-sm flex-grow"}>
                Replace Video
              </span>
            </div>*/}
            <div>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<DeleteOutlinedIcon fontSize={"small"} />}
                onClick={handleDelete}
                sx={{
                  color: "#fff", // Text color
                  borderColor: "#942407", // Border color
                  backgroundColor: "#942407", // Background color
                  "&:hover": {
                    backgroundColor: "#7a1b03", // Darker red when hovered
                    borderColor: "#7a1b03",
                  },
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditVideo;
