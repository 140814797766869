import { React, useState, useEffect } from "react";
import { Box, Link, Stack, Tooltip } from "@mui/material";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";

import Iconify from "../iconify";
import { useNavigate } from "react-router-dom";

import { db } from "../../firebase.js";
import { doc, updateDoc } from "firebase/firestore";

export default function ProjectList({ projects, getProjects, view }) {
  const navigate = useNavigate();
  const [rows, setRows] = useState(projects);

  const handleDelete = async (id) => {
    //console.log(id);
    setRows(rows.filter((row) => row.id !== id));
    const projectRef = doc(db, "projects", id);

    await updateDoc(projectRef, {
      archived: true,
    });

    await getProjects();
  };

  const handleEdit = (id, type) => {
    //console.log(id, type);
    handleUserNavigation(id, type);
  };

  const handleNameClick = (id, type) => {
    // console.log(id, type);
    // Navigate to a new page based on the ID
    handleUserNavigation(id, type);
  };

  const handleUserNavigation = (projectId, type) => {
    // default audio
    let path = `/editor?id=${projectId}`;

    if (type === "video") {
      path = `/v/${projectId}`;
    }

    navigate(path);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
      renderCell: (params) => (
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          style={{ height: "100%" }}
        >
          <img
            src={
              params.row.type === "audio" ? "/audio-waves.png" : "/video.png"
            }
            alt="Audio"
            style={{ maxWidth: "20px", height: "auto" }}
          />
          <Link
            variant="subtitle1"
            onClick={() => handleNameClick(params.row.id, params.row.type)}
            style={{ cursor: "pointer" }}
          >
            {params.value}
          </Link>
        </Stack>
      ),
    },
    { field: "created", headerName: "Created", flex: 0.1 },
    {
      field: "actions",
      type: "actions",
      cellClassName: "actions",
      headerName: "",
      flex: 0.1,
      sortable: false,
      filterable: false,
      getActions: ({ id, row }) => {
        return [
          <Tooltip title="Edit Project" placement="top">
            <GridActionsCellItem
              label="Edit"
              icon={<Iconify icon="material-symbols:edit" />}
              onClick={() => handleEdit(id, row.type)}
            />
          </Tooltip>,
          <Tooltip title="Archive Project" placement="top">
            <GridActionsCellItem
              label="Delete"
              icon={<Iconify icon="material-symbols:delete-outline" />}
              onClick={() => handleDelete(id)}
            />
          </Tooltip>,
        ];
      },
    },
  ];

  return (
    <Box sx={{ height: 1135, width: "100%" }}>
      <DataGrid
        density="comfortable"
        disableRowSelectionOnClick
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
          sorting: {
            sortModel: [{ field: "created", sort: "desc" }],
          },
        }}
      />
    </Box>
  );
}
