import { fabric } from "fabric";
import { uniqBy } from "lodash";
import ObjectID from "bson-objectid";
import { propertiesToInclude } from "../customFabric";

export function resetIDsOnClone(object) {
  if (object.__skipIdReset) return delete object.__skipIdReset;

  object.__prevId = object._id;
  object._id = ObjectID().toHexString();

  if (object.type === "group" || object.type === "blendObjects") {
    const objects = object.getObjects();

    objects.forEach((obj) => {
      if (obj.__skipIdReset) return delete obj.__skipIdReset;
      obj._id = ObjectID().toHexString();
      resetIDsOnClone(obj);
    });
  } else if (object.type === "activeSelection") {
    const objects = object.getObjects();

    if (objects.every((obj) => obj.type === "artboard")) {
      objects.forEach((artboard) => {
        if (!artboard.__skipIdReset) {
          const artboardId = ObjectID().toHexString();
          artboard._id = artboardId;
        }

        artboard.forEachChild((child) => {
          if (child.__skipIdReset) return delete child.__skipIdReset;

          const childId = ObjectID().toHexString();
          child._id = childId;
          resetIDsOnClone(child);
        });
      });
    } else {
      objects.forEach((obj) => {
        if (obj.__skipIdReset) return delete obj.__skipIdReset;

        obj._id = ObjectID().toHexString();
        resetIDsOnClone(obj);
      });
    }
  } else if (object.type === "artboard") {
    object.children.forEach((child) => {
      if (child.__skipIdReset) return delete child.__skipIdReset;

      child.parentArtboard = object._id;
      child._id = ObjectID().toHexString();

      if (
        child.type === "blendObjects" ||
        child.type === "group" ||
        child.type === "mockup"
      ) {
        resetIDsOnClone(child);
      }
    });
  }
}

/**
 * @description Finds all group objects inside a given group
 *
 * @param {fabric.Group} parent
 * @param {[]} objects - best left empty
 *
 * @returns {fabric.Object[]}
 */
export function deepFindGroupedObjects(group, objects = []) {
  group._objects.forEach((obj) => {
    if (obj.type !== "group" && obj.type !== "blendObjects") {
      objects.push(obj);
    } else {
      deepFindGroupedObjects(obj, objects);
    }
  });

  return objects;
}
/**
 *
 * @param {fabric.Group} group
 */
export function cacheMaskGroups(group) {
  if (group.type !== "group") return;

  const isMaskGroup =
    !!group.maskType ||
    group._objects.find(
      (o) =>
        o.globalCompositeOperation === "destination-out" ||
        o.globalCompositeOperation === "destination-in" ||
        o.globalCompositeOperation === "source-in"
    );

  group.set({
    needsItsOwnCache: () => !!isMaskGroup,
  });

  group.forEachObject((obj) => {
    // get rid of the shadow and stroke, not supported in Fabric
    if (isMaskGroup) {
      delete obj.shadow;
      delete obj.strokeWidth;
      delete obj.stroke;
    }

    if (obj.type === "group") {
      cacheMaskGroups(obj);
    }
  });
}

/**
 * @description Finds all objects with specific type
 *
 * @param {fabric.Object} parent
 * @param {[]} objects - best left empty
 *
 * @returns {fabric.Object[]}
 */
export function deepFindObjectByType(
  parent,
  objects = [],
  type = "",
  type2 = ""
) {
  if (parent.type === type || parent.type === type2) {
    objects.push(parent);
  } else {
    (parent.objects || parent._objects || parent.children || []).forEach(
      (obj) => {
        if (obj.type === type || obj.type === type2) {
          objects.push(obj);
        } else if (obj.type === "group") {
          deepFindObjectByType(obj, objects, type);
        } else if (obj.type === "artboard") {
          deepFindObjectByType(obj, objects, type);
        }
      }
    );
  }

  return uniqBy(objects, "_id");
}

/**
 *
 * @param {fabric.Object} target
 * @param {string} property
 */
export function enlivenDynamicPatterns(target, property = "fill") {
  return new Promise((resolve) => {
    const { canvas } = target;
    const tempCanvasEl = document.createElement("canvas");

    const patternSourceCanvas = new fabric.StaticCanvas(tempCanvasEl, {
      enableRetinaScaling: true,
      width: target[property].patternSourceCanvas.width,
      height: target[property].patternSourceCanvas.height,
    });

    const canvasData = JSON.stringify(target[property].patternSourceCanvas);
    patternSourceCanvas.loadFromJSON(canvasData, () => {
      target[property] = new fabric.Pattern({
        ...target[property],
        patternSourceCanvas: patternSourceCanvas,
        source: patternSourceCanvas.getElement(),
      });

      if (target.group) target.group.dirty = true;
      patternSourceCanvas.renderAll();
      canvas && canvas.requestRenderAll();

      resolve(target);
    });
  });
}

/**
 * @description Finds all nested objects and children of the given parent
 * Useful for getting all objects inside nested groups
 *
 * @param {fabric.Group} group
 * @param {[]} objects - best left empty
 *
 * @returns {fabric.Object[]}
 */
export function getAllNestedObjects(group, objects = []) {
  if (!group) return objects;

  (group._objects || group.children || []).forEach((obj) => {
    if (obj.type === "group") {
      getAllNestedObjects(obj, objects);
    } else if (obj.type === "artboard") {
      getAllNestedObjects(obj, objects);
    } else {
      objects.push(obj);
    }
  });

  return objects;
}

export function getPageBoundings(page) {
  let list = [];
  if (page.productSideData?.boundingRectList)
    list = page.productSideData.boundingRectList;

  return list;
}

export async function cloneObject(object) {
  return new Promise(async (resolve) => {
    let newObj = await object.cloneAsync(propertiesToInclude);
    newObj._id = ObjectID().toHexString();
    resolve(newObj);
  });
}
export function switchObjectParent(opt) {
  const { target } = opt;
  let canvas = this;
  let absolutePointer = canvas.getPointer(opt.e, true);
  if (opt.calculatePointerManually) {
    absolutePointer = {
      x: target.left * canvas.getZoom() + canvas.viewportTransform[4],
      y: target.top * canvas.getZoom() + canvas.viewportTransform[5],
    };
  }

  if (target.group && target.group.type === "group") return false;

  const { clipArea } = target;

  // Drag out of a artboard
  if (clipArea) {
    // while dragging if object moves from one artboard to another artboard
    let artboards = canvas.getObjects("artboard");

    let indexOfParent = artboards.indexOf(target.__parentArtboard);
    if (indexOfParent > -1) {
      artboards.splice(indexOfParent, 1);
      artboards = artboards.filter((artboard) => {
        if (
          artboard.visible &&
          artboard.containsPoint(
            absolutePointer,
            undefined,
            undefined,
            undefined,
            true
          )
        ) {
          return true;
        }
        return false;
      });
      let artboard = artboards.pop();
      if (artboard && artboard !== target.__parentArtboard) {
        target.__parentArtboard.removeChild(target);
        artboard.addChild(target);
        if (target.group) target.group.bringToFront();
        else target.bringToFront();
        target.setCoords();
        return true;
      }
    }
  } else {
    // Drag into an artboard
    let artboards = canvas.getObjects("artboard").filter((artboard) => {
      if (
        artboard.visible &&
        artboard.containsPoint(
          absolutePointer,
          undefined,
          undefined,
          undefined,
          true
        )
      ) {
        return true;
      }
      return false;
    });
    let artboard = artboards.pop();
    if (artboard) {
      artboard.addChild(target);
      if (target.group) target.group.bringToFront();
      else target.bringToFront();
      target.setCoords();

      return true;
    }
  }
}

/**
 *
 * @param {fabric.Object} object
 *
 * @returns {fabric.Group}
 */
export function findTopMostGroup(object) {
  let group = object?.group;

  while (group?.group) {
    group = group?.group;
  }

  return group;
}

/**
 * Check if object is in edit mode
 *
 * @param {fabric.Object} object
 * @returns {boolean}
 */
export function isEditing(object) {
  if (!object) return false;

  return (
    object.__cropMode ||
    object.__warpMode ||
    object.__perspectiveMode ||
    object.__editMode ||
    object.editMode ||
    object.isEditing
  );
}

export function handleSkipObjectsSelection(opts) {
  let targets = opts.selected;
  if (targets.filter((a) => a.skipFromSelection).length) {
    this.discardActiveObject();
    let objects = targets.filter((a) => !a.skipFromSelection);
    if (objects.length > 1) {
      let newActive = new fabric.ActiveSelection(objects, { canvas: this });
      this.setActiveObject(newActive);
    } else if (objects.length === 1) {
      this.setActiveObject(objects[0]);
    }
  }
}
export function deepFindVideos(parent, objects = []) {
  if (!parent) return objects;

  if (parent.type === "video") {
    objects.push(parent);
  } else {
    (parent._objects || parent.objects || parent.children || []).forEach(
      (obj) => {
        if (obj?.type === "video") {
          objects.push(obj);
        } else if (obj?.type === "group") {
          deepFindVideos(obj, objects);
        } else if (obj?.type === "artboard") {
          deepFindVideos(obj, objects);
        }
      }
    );
  }

  return objects;
}

/**
 * @description clones the activeSelection and adds them to canvas
 *
 * @param {fabric.Object} target
 * @param {number} offsetX
 * @param {number} offsetY
 * @param {boolean} onDrag - determines if it was cloned via mouse drag or not
 * @returns {Promise<fabric.Object>} cloned
 */
export function cloneActiveSelection(
  target,
  offsetX = 0,
  offsetY = 0,
  onDrag,
  returnOnly = false
) {
  return new Promise((resolve, reject) => {
    const { canvas } = target;

    const allTargetVideos = deepFindVideos(target);

    target.clone((cloned) => {
      const allVideos = deepFindVideos(cloned);

      cloned.canvas = canvas;
      cloned.left += offsetX;
      cloned.top += offsetY;

      cloned.forEachObject(async (obj) => {
        if (obj.type === "artboard" && !obj.__skipIdReset) {
          obj.children.forEach(async (child) => {
            child.left += offsetX;
            child.top += offsetY;

            obj.updateChildAnimations(child, offsetX, offsetY);
          });

          delete obj.mockupItem;
          delete obj.sectionId;
        }

        resetIDsOnClone(obj);

        if (!returnOnly) {
          obj.__skipAddChildEvent = true;
          canvas.add(obj);

          if (obj.type === "audio" || obj.isVideo) {
            delete obj.actor;
            delete obj.animations;

            if (obj.isVideo) {
              await obj.setVideoElement(obj.videoSrc);
            }
          }

          if (obj.__addArtboards) {
            delete obj.__addArtboards;
          }

          obj.__parentArtboard?.fire("child:added", {
            target: obj.__parentArtboard,
            obj,
            action: "childAdded",
          });
          obj.__parentArtboard?.canvas.fire("child:added", {
            target: obj.__parentArtboard,
            obj,
            action: "childAdded",
          });
          obj.__parentArtboard?.canvas.fire("artboard:child:added", {
            target: obj.__parentArtboard,
            obj,
            action: "childAdded",
          });
        }
      });

      if (!onDrag && !returnOnly) {
        // if it was cloned via mouse alt+drag
        // we need to fire this when the new position
        // of the clone is set
        canvas.fire("user:object:added", { target: cloned, cloned: true });
      }
      cloned.setCoords();

      allVideos.forEach((video, i) => {
        const original = allTargetVideos[i];

        if (original?.__videoElement) {
          video.setElement(original.__videoElement, {
            scaleX: original.scaleX,
            scaleY: original.scaleY,
            width: original.width,
            height: original.height,
            layout: original.layout,
            cropX: original.cropX,
            cropY: original.cropY,
            warpCoords: original.warpCoords,
            perspectiveCoords: original.perspectiveCoords,
            cornerRadius: original.cornerRadius,
            ovalArc: original.ovalArc,
          });
          video.__videoElement = original.__videoElement;
        }
      });

      if (!onDrag && !returnOnly) canvas.setActiveObject(cloned);

      if (cloned) resolve(cloned);
      else reject(new Error("Nothing cloned"));
    }, propertiesToInclude);
  });
}
