import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { CanvasStore } from "../../canvas/store/canvasStore";
import { handleAddAudio } from "../../canvas/utils/helper";
import ShowMessage from "../../components/ShowMessage/ShowMessage";
import { db } from "../../firebase";
import { useUserAuth } from "../../UserAuthContext";

const storage = getStorage();

const Uploader = () => {
  const { user } = useUserAuth();
  const { activeCanvas } = useContext(CanvasStore);
  const fileTypes = ["mp3", "wav", "m4a", "aac"];
  const [showError, setShowError] = useState(false);

  const handleAudio = async (file) => {
    if (file.type && file.type.startsWith("audio/")) {
      const fname = file.name;
      const uid = user.uid;

      const storageRef = ref(storage, `${uid}/uploads/${fname}`);
      console.log(storageRef);
      await uploadBytes(storageRef, file);

      const url = await getDownloadURL(storageRef);

      await addDoc(collection(db, "uploads"), {
        user: user.email,
        file_name: fname,
        timestamp: serverTimestamp(),
        type: "music",
        url: url,
      });

      // let url = URL.createObjectURL(file);
      // console.log(url, file.name);
      handleAddAudio(url, file.name, activeCanvas);
    } else {
      alert("Please upload an audio file.");
    }
  };

  const handleShowMessage = () => {
    setShowError(true);
  };

  return (
    <FileUploader
      handleChange={handleAudio}
      multiple={false}
      name="file"
      types={fileTypes}
    >
      <div className="cursor-pointer rounded-lg bg-black border border-dashed border-gray-300 text-center p-4 bg-gray-900 transition duration-300 ease-in-out hover:border-primary hover:hover:bg-primary/10">
        <div className="flex items-center justify-center flex-col ">
          <CloudUploadOutlinedIcon className=" text-[#71737c]" />
          <span className="text-white text-[13px] text-blueDark my-1">
            Upload File
          </span>
          <span className=" text-xs text-brownMedium  max-w-[130px] ">
            Click to browse, or drag & drop a file here
          </span>
        </div>
      </div>
      <ShowMessage
        showFileError={showError}
        setShowFileError={setShowError}
        severity="error"
        message="File size exceeds maximum limit (50MB)"
      />
    </FileUploader>
  );
};

export default Uploader;
