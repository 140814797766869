import slugify from 'slugify';
import resizeImage from 'smart-img-resize';

/**
 * Converts Blob files to base64 dataUri
 * Ready to be saved on AWS S3
 *
 * @param {Binary} blob
 *
 */

export const blobToDataUri = blob => {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = _ => {
      const dataUri = reader.result;
      resolve(dataUri);
    };
    reader.onerror = error => {
      reject(error);
    };
  });
};

export const dataUriToBlob = (b64Data, contentType, sliceSize = 512, name) => {
  return new Promise(resolve => {
    contentType = contentType || 'image/png';

    const byteCharacters = atob(b64Data.replace(/^data:image\/(\w|\+xml)+;base64,/, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType, name });
    if (name) blob.name = name;
    const urlCreator = window.URL || window.webkitURL;
    blob.preview = urlCreator.createObjectURL(blob);
    resolve(blob);
  });
};

/**
 * gets the size + size unit of the file
 * Based on the bytes given
 *
 * @param {Int} bytes
 *
 */
export const bytesToSize = (bytes, float = 2) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / (1024 ** i)).toFixed(float)} ${sizes[i]}`;
};
export const sizeToBytes = (amount, format) => {
  const base = 1024;
  const sizes = {
    'KB': Math.pow(base, 1),
    'MB': Math.pow(base, 2),
    'GB': Math.pow(base, 3),
    'TB': Math.pow(base, 4),
    'PB': Math.pow(base, 5),
    'EB': Math.pow(base, 6),
    'ZB': Math.pow(base, 7),
    'YB': Math.pow(base, 8)
  };

  return amount * sizes[format];
};

// Generates URL from AWS Key
export const urlGen = (Key, version) => {
  if (!Key) return null;

  if (Key.startsWith('blob:') || Key.startsWith('http:') || Key.startsWith('https:')) {
    return Key;
  }

  let url = `https://s3.${process.env.REACT_APP_BUCKET_REGION}.amazonaws.com/${process.env.REACT_APP_BUCKET}/${Key}`;

  if (version) url += ver();
  return url;
};

// Generates REACT_APP_CDN URL from AWS Key
export const cdnGen = (Key) => {
  if (!Key) return null;
  if (Key.startsWith('blob:') || Key.startsWith('http:') || Key.startsWith('https:')) {
    return Key;
  }

  let cdn = `${process.env.REACT_APP_CDN}/${Key}`;
  if (!Key) cdn = null;

  return cdn;
};

// Adds ver cache buster to the end of the URL
export const ver = () => {
  return '?ver=' + Date.now();
};

export const canvasToFile = async (canvas, mimeType = 'image/png', quality = 1, name) => {
  return new Promise((resolve, reject) => {
    if (!canvas) return reject('No canvas supplied');

    canvas.toBlob(blob => {
      const preview = window.URL.createObjectURL(blob);
      blob.preview = preview;
      if (name) blob.name = name;

      resolve(blob);
    }, mimeType, quality);
  });
};

export const readAsFile = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', async e => {
      resolve(new Blob([reader.result], { type: file.type }));
    });
    reader.readAsArrayBuffer(file);
  });
};

export const resizeImageAsync = (file, options) => {
  return new Promise((resolve, reject) => {
    resizeImage(file, {
      outputFormat: 'canvas',
      targetWidth: options.width,
      targetHeight: options.height,
      crop: options.crop || false,
      forceRatio: options.forceRatio || false
    }, async (error, canvas) => {
      if (error) return reject(error);

      canvas.toBlob(blob => {
        if (!blob) return reject(new Error('Could not convert canvas to blob'));

        blob.name = options.fileName;

        resolve(blob);
      }, options.contenttype);
    });
  });
};

export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
  var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

export function isVideoFile(src) {
  if (!src) return false;
  return (
    src.endsWith('.mp4') ||
    src.endsWith('.webm')
  );
}

export function isGif(src) {
  if (!src) return false;

  return src.slice().split('?ver=')[0].endsWith('.gif');
}

export function sanitizeFileName(fileName, slug = true) {
  const regex = /[/\\<>?"';:|!$%^&=#,*+~()'"!:@]/g;
  if (slug) {
    return slugify(fileName, { strict: false, remove: regex });
  } else {
    return fileName.replace(regex, '');
  }
}


export const mimeToExt = mime => {
  switch (mime) {
    case 'image/png':
      return 'png';
    case 'image/jpeg':
      return 'jpg';
    case 'image/gif':
      return 'gif';
    case 'image/webp':
      return 'webp';
    case 'video/webm':
      return 'webm';
    case 'video/mp4':
      return 'mp4';
    case 'image/svg+xml':
      return 'svg';
    default:
      break;
  }
};
