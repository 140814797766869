import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Divider,
  Typography,
  Paper,
  Stack,
} from "@mui/material";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { db } from "../../../firebase.js";
import { doc, onSnapshot, getDoc } from "firebase/firestore";

import { useUserAuth } from "../../../UserAuthContext";
import { useNavigate, useLocation } from "react-router-dom";

// hooks
import useResponsive from "../../../hooks/useResponsive";

// components
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";

//
import navConfig from "./config";
import Iconify from "../../../components/iconify/Iconify.js";

// ----------------------------------------------------------------------

const NAV_WIDTH = 220;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { user, isTeamMember } = useUserAuth();
  const [usage, setUsage] = useState(0);
  const [quota, setQuota] = useState(10000);
  const [cloningUsage, setCloningUsage] = useState(0);
  const [cloningQuota, setCloningQuota] = useState(0);
  const [usageLabel, setUsageLabel] = useState("Usage");
  const navigate = useNavigate();

  useEffect(() => {
    let snapDoc = doc(db, "customers", user.uid);

    isTeamMember().then((team) => {
      if (team) {
        snapDoc = doc(db, "teams", team);
      }
      // subscribe to teams to get usage

      try {
        const unsubscribe = onSnapshot(snapDoc, (doc) => {
          const userData = doc.data();
          setUsage(userData.usage);
          setQuota(userData.quota);
          setCloningUsage(userData.cloning_usage || 0);
          setCloningQuota(userData.cloning_quota);
          if (team) {
            setUsageLabel(`${userData.name} Usage`);
          }
        });

        return () => {
          unsubscribe();
        };
      } catch (error) {
        console.log(error);
      }
    });
  }, []);

  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
    height: 5,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.primary[theme.palette.mode === "light" ? 300 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor:
        value > 70
          ? "#B71C1C"
          : theme.palette.mode === "light"
          ? "#1565C0"
          : "#308fe8",
    },
  }));

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <Logo />
      </Box>

      <NavSection data={navConfig} />
      {/* gap between nav items and usage */}
      <Divider variant="middle"></Divider>

      <Box sx={{ flexGrow: 1, minHeight: "2vh" }} />

      <Box
        sx={{
          px: 2.5,
          pb: 3,
        }}
      >
        <Paper elevation={2} sx={{ border: "1px" }}>
          <Stack padding={2}>
            <Typography variant="subtitle1" sx={{ fontSize: "12px" }}>
              {usageLabel}
            </Typography>
          </Stack>
          <Stack alignItems="center" padding={1} spacing={2}>
            <Box minHeight={30} sx={{ flexGrow: 1, textAlign: "center" }}>
              <Box sx={{ minWidth: 130, mr: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={(usage / quota) * 100}
                />
              </Box>
              <Typography variant="caption">
                TTS:
                <strong> {((usage / quota) * 100).toFixed(0)}%</strong>
              </Typography>
            </Box>
            {/* Cloning usage bar */}
            <Box minHeight={25} sx={{ flexGrow: 1, textAlign: "center" }}>
              <Box sx={{ minWidth: 130, mr: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    cloningQuota === 0 ? 0 : (cloningUsage / cloningQuota) * 100
                  }
                />
              </Box>
              <Typography variant="caption">
                Cloning:
                <strong>
                  {" "}
                  <strong>
                    {" "}
                    {cloningQuota === 0
                      ? 0
                      : ((cloningUsage / cloningQuota) * 100).toFixed(0)}
                    %
                  </strong>
                </strong>
              </Typography>
            </Box>
          </Stack>
        </Paper>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: "#000",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
