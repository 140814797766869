import React, {  useEffect, useRef } from "react";
import {fabric} from 'fabric'
function DragableView({onChangeComplete=()=>{},layersScrollRef,max=fabric.MAX_SAFE_INTEGER,min=fabric.MIN_SAFE_INTEGER,pixelsPerSecond,...props}) {
  const refIsMouseDown=useRef();

  const ref = useRef({
    div: null,
    isDragging: false,
    initialMouseX: 0,
  });
  const { current: data } = ref;

  function getDelta(mouseX) {
    if (mouseX < 0) mouseX = 0;
    return (mouseX - refIsMouseDown.current.clientX) / pixelsPerSecond
  }



  const handleMouseDown = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!data.div) return;
    if (props.disabled) return;
    data.isDragging = true;
    data.initialMouseX = event.clientX;

    refIsMouseDown.current=event;
    window.addEventListener("mouseup", handleMouseUp );
    window.addEventListener("mousemove", handleMouseMove );
  };
  const autoScrollRef=useRef();
  const handleMouseMove  = (event) => {

    event.stopPropagation();
    event.preventDefault();
    let delta=getDelta(event.clientX);
    let newValue=props.value + delta
    if(newValue<min){
      newValue=min;
    }else if( newValue > max){
      newValue=max;
    }
    props.onChange(newValue,delta);
    let rect = layersScrollRef.current.getBoundingClientRect();
    clearInterval(autoScrollRef.current);
    // direction
    //= event.clientX - refIsMouseDown.current.clientX
    let incrementedValue=pixelsPerSecond/5;

    if(delta>=0)
    {
      let diff=(rect.right  - event.clientX)

      //forward direction
      if(diff <= 40){
        // auto scroll start
        autoScrollRef.current= setInterval(()=>{
          refIsMouseDown.current.clientX -= incrementedValue;
          newValue+=0.2;
          delta+=0.2;
          if(newValue<min){
            newValue=min;
          }else if( newValue > max){
            newValue=max;
          }
          props.onChange(newValue,delta);
          layersScrollRef.current.scrollLeft += incrementedValue;
        },100)
      }
    }else{

      let diff=(event.clientX - rect.left)
      //backward direction
      if(diff <= 40){
        // auto scroll start
        autoScrollRef.current= setInterval(()=>{
          refIsMouseDown.current.clientX += incrementedValue;
          newValue -= 0.2;
          delta -= 0.2;
          if(newValue<min){
            newValue=min;
          }else if( newValue > max){
            newValue=max;
          }
          props.onChange(newValue,delta);
          layersScrollRef.current.scrollLeft -= incrementedValue;
        },100)
      }


    }
  };

  const handleMouseUp  = () => {
    onChangeComplete()
    clearInterval(autoScrollRef.current)
    window.removeEventListener("mouseup", handleMouseUp );
    window.removeEventListener("mousemove", handleMouseMove );
  };


  return (
    <div
      ref={(r) => {
        data.div = r;
      }}
      className={`absolute h-full ${props.className}`}
      style={{
        top: 0,
        bottom: 0,
        ...props.style,
      }}
      onMouseDown={handleMouseDown}
    >
      {props.children}
    </div>
  );
}

export default DragableView;
