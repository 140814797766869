import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";

const AutoComplete = ({
  onSelect,
  value = null,
  options,
  getOptionLabel,
  renderOption,
  placeholder,
  label,
  props,
  groupBy,
  size = "small",
}) => {
  return (
    <Autocomplete
      id="combo-box-demo"
      options={options}
      disablePortal
      clearIcon={null}
      value={value}
      sx={{
        "& .MuiInputBase-input": {
          fontSize: "14px",
          color: "white",
        },
      }}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => {
        return option?.label === value?.label;
      }}
      onSelect={onSelect}
      renderOption={renderOption}
      size={size}
      getOptionSelected={(option, value) => option.value === value.value}
      groupBy={groupBy}
      {...props}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default AutoComplete;
