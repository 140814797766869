import PropTypes from "prop-types";
import { NavLink as RouterLink } from "react-router-dom";
// @mui
import { Box, List, ListItemText, Divider } from "@mui/material";
//
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import Iconify from "../iconify";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item, index) => (
          <div key={item.title}>
            {index === data.length - 1 ? (
              <>
                <Box mt={2}></Box>
                <Divider variant="middle"></Divider>
                <Box mt={2}></Box>
              </>
            ) : null}
            <NavItem item={item} />
          </div>
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        "&.active": {
          color: "text.primary",
          bgcolor: "action.selected",
          fontWeight: "fontWeightBold",
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText
        disableTypography
        primary={
          <div>
            {title}
            {title === "Voice Cloning" ? (
              <Iconify color="#B71C1C" icon="ic:outline-fiber-new" />
            ) : (
              ""
            )}
          </div>
        }
      />

      {info && info}
    </StyledNavItem>
  );
}
