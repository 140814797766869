import { createTheme } from "@mui/material/styles";
import Inter from "./fonts/Inter-Regular.ttf";
// import { Poppins } from 'next/font/google';
// const poppins = Poppins({
//   subsets: ['latin'],
//   display: 'swap',
//   variable: '--font-poppins',
//   weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
// });

export const primaryColor = "#0098fd";
const secondaryColor = "#192033";
const backgroundColor = "#ffffff";
const paperColor = "#09090D";
const textColorPrimary = "#5c5e65";
const textColorSecondary = "#D0CFD1";
const dividerColor = "#3A3940";

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 375,
      tablet: 745,
      laptop: 1440,
      desktop: 1728,
    },
  },
  typography: {
    fontFamily: "Inter, Roboto",
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    background: {
      default: backgroundColor,
      paper: paperColor,
    },
    text: {
      primary: textColorPrimary,
      secondary: textColorSecondary,
    },
    divider: dividerColor,
    mode: "dark",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Inter'), local('Inter-Regular'), url(${Inter}) format('ttf');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "capitalize",
        },
        outlined: {
          color: "white",
          borderColor: "#3A3940",
          "&:hover": {
            borderColor: "#3A3940",
          },
        },
      },
      variants: [
        {
          props: { variant: "black" },
          style: {
            backgroundColor: "#192033",
            color: "white",
          },
        },
        {
          props: { variant: "neutral" },
          style: {
            backgroundColor: "#eeeef0", // Set the background color to white
            color: "#5c5e65",
            // '&:hover': {
            //   background: 'white',
            //   color: 'transparent',
            // },
          },
        },
      ],
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: "#ffffff",
        },
        groupLabel: {
          backgroundColor: "#eeeef0",
          color: "gray",
        },
        // popper: {
        //   minWidth: '250px',
        // },
      },
    },
    // MuiSlider: {
    //   styleOverrides: {
    //     thumb: {
    //       '&:after': {
    //         width: '20px',
    //         height: '20px',
    //         backgroundColor: secondaryColor,
    //       },
    //     },
    //     root: {
    //       '.MuiSlider-track': {
    //         backgroundImage: `linear-gradient(45deg,${gradientColorStart},${gradientColorEnd})`,
    //         border: 'none',
    //       },
    //       '.MuiSlider-thumb': {
    //         backgroundColor: 'transparent',
    //         boxShadow: `0 0 0 3px ${secondaryColor}`,
    //         width: '30px',
    //         height: '30px',
    //       },
    //     },
    //   },
    // },
  },
});

export default theme;
