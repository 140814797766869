import React, { useEffect, useState } from "react";

import ButtonTabs from "./components/ButtonTabs";
import Header from "../../../components/Header";
import VideoSlider from "./components/VideoSlider";
import { debounce } from "lodash";

//import { fetchVideos } from '../../../firebaseServices/index';
import { functions, httpsCallable } from "../../../firebase"; // import Firebase functions instance

const StockVideos = () => {
  const [videosList, setVideosList] = useState([]);
  const [filteredVideosList, setFilteredVideosList] = useState([]);
  const [query, setQuery] = useState("nature");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchVideoStocks = async () => {
      if (!query) {
        return;
      }
      setLoading(true);
      const getMediaFunction = httpsCallable(functions, "getMedia");

      const result = await getMediaFunction({ query });
      const videos = result.data.videos.videos;

      // console.log(videos);
      setVideosList(videos);
      setLoading(false);
      /*const list = await fetchVideos();
            setVideosList(list);
            setFilteredVideosList(list);*/
    };

    fetchVideoStocks();
  }, [query]);

  const handleSearchVideo = (value) => {
    const searchValue = value.trim().toLowerCase();
    if (!searchValue) {
      return;
    } else {
      console.log(searchValue);
      setQuery(searchValue);
    }
  };

  const debouncedHandleSearchVideo = debounce(handleSearchVideo, 500);

  return (
    <div className="mt-5">
      <Header onSearch={debouncedHandleSearchVideo} heading={"Stock Videos"} />
      {/* <ButtonTabs /> */}
      <VideoSlider videoList={videosList} loading={loading} />
    </div>
  );
};

export default StockVideos;
