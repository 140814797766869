import { fabric } from "fabric";

export function addText(textObject = null, activeCanvas) {
  if (!textObject) return;
  let text = new fabric.IText(textObject.title, {
    left: 300,
    top: 300,
    fill: textObject.fill ?? "#fff",

    fontSize: textObject.fontSize || 72,
    fontFamily: textObject.fontFamily,
    fontWeight: textObject.fontWeight,
    stroke: textObject.stroke ?? null,
    strokeWidth: textObject.strokeWidth ?? 0,
    backgroundColor: textObject.backgroundColor ?? null,
    padding: textObject.padding ?? 1,
    shadow: textObject.shadow ?? null,
  });

  if (textObject.boxed) {
    let rect = new fabric.Rect({
      left: text.left - 20, // Adjust the position to add padding
      top: text.top - 10, // Adjust the position to add padding
      width: text.width + 40, // Add padding to the width
      height: text.height + 20, // Add padding to the height
      fill: "rgba(183, 28, 28, 1)", // Background color with transparency
      //rx: 10, // Optional: Rounded corners
      //ry: 10, // Optional: Rounded corners
    });
    //let group = new fabric.Group([rect, text], {
    //left: text.left,
    //top: text.top,
    // You can add additional properties here if needed
    //});
    //activeCanvas.add(group).setActiveObject(group);
    activeCanvas.add(rect);
  }

  if (textObject.underline) {
    let line = new fabric.Rect({
      left: text.left, // Adjust the position to add padding
      top: text.top + text.height + 10, // Adjust the position to add padding
      width: text.width + 10, // Add padding to the width
      height: 10, // Add padding to the height
      fill: "rgba(255, 255, 255, 1)", // Background color with transparency
      //rx: 10, // Optional: Rounded corners
      //ry: 10, // Optional: Rounded corners
    });
    console.log(line);
    activeCanvas.add(line);
  }
  activeCanvas.add(text).setActiveObject(text);
  activeCanvas.renderAll();
  return text;
}
