import React, { useContext, useEffect, useState } from "react";
import PageHeader from "../shared/PageHeader";
import { CanvasStore } from "../canvas/store/canvasStore";
import { withModifiedEvent } from "../canvas/utils/helper";
import { fabric } from "fabric";

const Animation = ({ onClose }) => {
  const [activeAnimation, setActiveAnimation] = useState(null);
  const { activeCanvas, activeObject } = useContext(CanvasStore);

  const [animationsList] = useState([
    { name: "None", src: "fade_animation.mp4" },
    { name: "Fade In", src: "fade_animation.mp4" },
    { name: "Fade Out", src: "slide_animation.mp4" },
    { name: "Slide In", src: "zoom_animation.mp4" },
    { name: "Slide Out", src: "rotate_animation.mp4" },
    { name: "Breathing", src: "rotate_animation.mp4" },
  ]);

  const handleAnimationClick = (animationName) => {
    setActiveAnimation(animationName);
    if (animationName === "None") {
      onClose(true);
    } else if (animationName === "Fade In") {
      console.log("here", activeObject);
      withModifiedEvent(
        activeObject,
        activeCanvas,
        () => {
          const animationDuration = 1000; // duration of the animation

          //activeObject.animationStartTime =
          //  activeObject.animationStartTime + animationDuration / 1000;

          // Animate to opacity 1 at the start
          activeObject.animate("opacity", 1, {
            duration: animationDuration,
            onChange: activeCanvas.renderAll.bind(activeCanvas),
            onComplete: () => {
              // Ensure canvas is re-rendered after animation completes
              activeCanvas.renderAll();
            },
          });
        },
        ["fadeIn"],
        "fadeInChange"
      )();
      //activeCanvas.renderAll();
      //return text;
      //onClose(false);
    } else if (animationName === "Slide Out") {
      onClose(false);
    } else if (animationName === "Zoom In") {
      onClose(false);
    } else if (animationName === "Zoom Out") {
      onClose(false);
    } else if (animationName === "Rotate In") {
      onClose(false);
    } else if (animationName === "Rotate Out") {
      onClose(false);
    } else if (animationName === "Breathing") {
      onClose(false);
    } else if (animationName === "Slide Out") {
      onClose(false);
    } else if (animationName === "Slide In") {
      onClose(false);
    } else if (animationName === "Fade Out") {
      onClose(false);
    } else {
      onClose(false);
    }
  };

  return (
    <div>
      <PageHeader
        heading="Animation"
        onClicked={() => onClose(true)}
        showBackButton={true}
      />

      <div className={"container-animation"}>
        {animationsList.map((animation, index) => (
          <div className="animation-container hdYsif" key={index}>
            <div className="eMLMbF hjVfOB ">
              <div
                className="ihTHAJ"
                onClick={() => handleAnimationClick(animation.name)}
              >
                {animation.name === "None" ? (
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="sc-aXZVg gFHStW"
                  >
                    <path
                      d="M32 17c0-8.28427-6.7157-15-15-15C8.71573 2 2 8.71573 2 17c0 8.2843 6.71573 15 15 15 8.2843 0 15-6.7157 15-15ZM7 6l20 21"
                      stroke="currentColor"
                      stroke-width="3"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    width="80"
                    height="80"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.3 47h-2.5l4.6-13h2.9l4.6 13h-2.5l-3.5-10.4h-.1L25.3 47Zm0-5.1h7v1.9h-7v-2ZM36.5 47V34h2.4v4.8l.6-.8.8-.7a3 3 0 0 1 1.5-.2 3.7 3.7 0 0 1 3.5 2.2 8.2 8.2 0 0 1 0 5.6 3.7 3.7 0 0 1-3.5 2.3c-.6 0-1-.1-1.4-.3l-1-.7-.4-.7h-.2V47h-2.3Zm2.3-5c0 .7.1 1.3.3 1.8.2.4.4.8.8 1 .3.3.7.5 1.2.5s1-.2 1.3-.4a3.8 3.8 0 0 0 1.1-2.8c0-.6-.1-1.2-.3-1.6-.2-.5-.4-.9-.8-1.1A2 2 0 0 0 41 39a2 2 0 0 0-1.2.3c-.4.3-.6.7-.8 1.1-.2.5-.3 1-.3 1.7Zm13 5.2a4 4 0 0 1-2.5-.7c-.7-.4-1.2-1-1.6-1.7a6 6 0 0 1-.5-2.7c0-1 .1-1.8.5-2.6s1-1.4 1.7-1.8c.7-.4 1.5-.6 2.4-.6.8 0 1.5.1 2.2.4a3.6 3.6 0 0 1 2 3h-2.1c-.1-.4-.3-.8-.7-1.1-.3-.3-.8-.5-1.3-.5a2 2 0 0 0-1.3.4l-.8 1a5.2 5.2 0 0 0 0 3.5c.2.5.5.9.8 1.1.4.3.8.4 1.3.4.3 0 .6 0 .9-.2.3-.1.5-.3.7-.6.2-.2.3-.5.4-.9H56a3.6 3.6 0 0 1-2 3.1c-.7.3-1.4.5-2.2.5Z"
                      fill="url(#fadeGradient)"
                    ></path>
                    <defs>
                      <linearGradient
                        id="fadeGradient"
                        x1="39.5"
                        y1="31.1"
                        x2="39.5"
                        y2="47.3"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="currentColor"></stop>
                        <stop
                          offset="1"
                          stopColor="currentColor"
                          stopOpacity="0"
                        ></stop>
                      </linearGradient>
                    </defs>
                  </svg>
                )}
              </div>
            </div>
            <span className=" frbGcB">{animation.name}</span>
            <div className=" gCOnki"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Animation;
